import Button from 'components/element/button'
import Checkbox from 'components/element/checkbox'
import Input from 'components/element/input'
import Layout from 'components/layouts/site'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from 'firebaseConfig'
import { ReactComponent as LogoIcon } from 'images/logo.svg'
import { lighten } from 'polished'
import { FormEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

export type LoginErrorProps = { errorCode: string; message: string }

const Login = () => {
	const navigate = useNavigate()

	const [isChecked, setIsChecked] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [error, setError] = useState<LoginErrorProps>({ errorCode: '', message: '' })
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const signin = async (e: FormEvent) => {
		e.preventDefault()
		setIsLoading(true)
		setError({ errorCode: '', message: '' })
		try {
			await signInWithEmailAndPassword(auth, email, password)

			navigate('/timeline')
		} catch (error: any) {
			switch (error.code) {
				case 'auth/user-not-found':
					setError({
						errorCode: error.code,
						message: `Look's like you don't have an account yet.`
					})
					break
				case 'auth/invalid-email':
					setError({
						errorCode: error.code,
						message: `Enter a valid email address.`
					})
					break
				case 'auth/internal-error':
					setError({
						errorCode: error.code,
						message: 'Fill in all the required fields correctly.'
					})
					break
				default:
					setError({
						errorCode: error.code,
						message: `Something went wrong.`
					})
			}

			setIsLoading(false)
		}
	}

	return (
		<Layout>
			<Wrapper>
				<Logo>
					<LogoIcon />
				</Logo>
				<Title>Login</Title>
				<Text>Enter your email and password to continue with your account</Text>
				<form onSubmit={signin}>
					<Form>
						<Input
							type='text'
							value={email}
							label='Email'
							onChange={(e) => setEmail(e.currentTarget.value)}
						/>
						<Input
							type='password'
							value={password}
							label='Password'
							onChange={(e) => setPassword(e.currentTarget.value)}
						/>
						<Checkbox
							value={isChecked}
							onCheck={() => setIsChecked((isChecked: boolean) => !isChecked)}
							label='Remember me'
						/>
						{error?.message && (
							<Error>
								<ErrorMessage>{error.message}</ErrorMessage>
								{error.errorCode === 'auth/user-not-found' && (
									<>
										Would you like to{' '}
										<ErrorAction to='/signup'>create an account?</ErrorAction>
									</>
								)}
							</Error>
						)}

						<Button type='submit' isLoading={isLoading}>
							Login
						</Button>
						<EntryLink>
							Don't have an account? <Link to='/signup'>Signup</Link>
						</EntryLink>
					</Form>
				</form>
			</Wrapper>
		</Layout>
	)
}

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	max-width: 320px;
	min-height: 100vh;
	width: 100%;
`

export const Form = styled.div`
	display: flex;
	flex-direction: column;
	& > * {
		margin-bottom: 24px;
	}
`

export const Error = styled.div`
	color: ${(props) => props.theme.colors.danger};
	font-size: 16px;
	text-align: center;
	background-color: ${(props) => lighten(0.56, props.theme.colors.danger)};
	padding: 10px;
	border-radius: 7px;
`

export const ErrorMessage = styled.span``

export const ErrorAction = styled(Link)`
	color: ${(props) => props.theme.colors.neutral000};
	text-decoration: underline;
`

export const Logo = styled.h1`
	font-size: 40px;
	margin-bottom: 24px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Title = styled.h2`
	font-weight: 600;
	font-size: 24px;
	line-height: 30px;
	margin-bottom: 10px;
`

export const Text = styled.p`
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 24px;
`

export const EntryLink = styled.p`
	color: ${(props) => props.theme.colors.neutral000};
	font-weight: 400;
	font-size: 18px;
	line-height: 22px;
`

export default Login
