import { map } from 'lodash'
import { transparentize } from 'polished'
import { FC, ReactNode } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import styled from 'styled-components/macro'

export type ContextMenuItemType = {
	icon?: ReactNode
	isDanger?: boolean
	label: string
	onClick: (props?: any) => void
}

export type ContextProps = {
	data?: any
	isVisible: boolean
	menu: Array<ContextMenuItemType>
	toggle: ReactNode
	onClose: () => void
	position: {
		top?: string
		right?: string
		bottom?: string
		left?: string
	}
}

const Context: FC<ContextProps> = ({
	data,
	isVisible,
	menu,
	onClose,
	toggle,
	position,
	...rest
}) => {
	const ref = useDetectClickOutside({ onTriggered: onClose })

	return (
		<Wrapper ref={ref} {...rest}>
			<Toggle>{toggle}</Toggle>
			{isVisible && (
				<Menu {...position}>
					{map(menu, ({ icon, isDanger, label, onClick }, index) => {
						return (
							<Item
								isDanger={isDanger}
								key={index}
								onClick={() => {
									if (data) {
										onClick(data)
									} else {
										onClick()
									}
									onClose()
								}}>
								<Icon>{icon}</Icon>
								<Label>{label}</Label>
							</Item>
						)
					})}
				</Menu>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`

const Toggle = styled.div`
	display: flex;
`

const Menu = styled.div<{ top?: string; right?: string; bottom?: string; left?: string }>`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border: 1px solid ${(props) => props.theme.colors.neutral005};
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding: 20px 0;
	position: absolute;
	z-index: ${(props) => props.theme.indexes.dropdown};
	${(props) => props.top && `top: ${props.top};`}
	${(props) => props.right && `right: ${props.right};`}
	${(props) => props.bottom && `bottom: ${props.bottom};`}
	${(props) => props.left && `left: ${props.left};`}
`

const Item = styled.button<{ isDanger?: boolean }>`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	color: ${(props) =>
		props.isDanger ? props.theme.colors.danger : props.theme.colors.neutral000};
	cursor: pointer;
	display: flex;
	min-width: 140px;
	padding: 12px 20px;
	white-space: nowrap;
	width: 100%;

	&:hover {
		background-color: ${(props) => props.theme.colors.neutral004};
	}

	&:active {
		opacity: 0.5;
	}
`

const Icon = styled.span`
	font-size: 16px;
	margin-right: 5px;
`

const Label = styled.span`
	font-size: 16px;
	font-weight: 600;
`

export default Context
