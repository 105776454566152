import { Body, Card, Footer, Header, Title } from 'components/block/modal'
import Button from 'components/element/button'
import { ReactComponent as BackIcon } from 'icons/backArrow.svg'
import { ReactComponent as DeleteIcon } from 'icons/delete.svg'
import { ReactComponent as ImagesIcon } from 'icons/images.svg'
import { filter, map } from 'lodash'
import { MomentProps } from 'providers/moments/types'
import { FC, RefObject } from 'react'
import styled from 'styled-components/macro'
import { AddPhoto, AddPhotoIcon, AddPhotoText } from './momentForm'

export type PhotoManagerProps = {
	clearPhotos: () => void
	fileInputRef: RefObject<HTMLInputElement>
	imageFiles: Array<File>
	imageUrls: Array<string>
	isOpen: boolean
	moment: MomentProps | null
	setIsOpen: (isOpen: boolean) => void
	setIsFormOpen: (isOpen: boolean) => void
	setImageFiles: (props: Array<File>) => void
	setImageUrls: (props: Array<string>) => void
}

const PhotoManager: FC<PhotoManagerProps> = ({
	clearPhotos,
	fileInputRef,
	imageFiles,
	imageUrls,
	isOpen,
	moment,
	setIsFormOpen,
	setIsOpen,
	setImageFiles,
	setImageUrls
}) => {
	const closePhotoManager = () => {
		setIsFormOpen(true)
		setIsOpen(false)
	}

	const deleteImage = (index: number) => {
		const filteredImage = filter(imageFiles, (image, i) => {
			return index !== i
		})
		setImageFiles(filteredImage)
	}

	return (
		<PhotoManagerCard>
			<Header>
				<Back type='button' onClick={closePhotoManager}>
					<BackIcon />
				</Back>
				<Title>Photo Manager</Title>
				<ClearPhotos type='button' onClick={clearPhotos}>
					<ClearPhotosIcon>
						<DeleteIcon />
					</ClearPhotosIcon>
					<ClearPhotosText>Delete all</ClearPhotosText>
				</ClearPhotos>
			</Header>
			<Body>
				<ImagesWrapper>
					{!Boolean(imageFiles.length) && (
						<AddPhoto onClick={() => fileInputRef.current?.click()}>
							<AddPhotoIcon>
								<ImagesIcon />
							</AddPhotoIcon>
							<AddPhotoText> Upload photos</AddPhotoText>
						</AddPhoto>
					)}
					{Boolean(imageFiles.length) &&
						map(imageFiles, (image, index) => {
							return (
								<ImageWrapper>
									<Delete
										type='button'
										onClick={() => deleteImage(index)}
										title='Remove photo'>
										<DeleteIcon />
									</Delete>
									<img key={index} src={URL.createObjectURL(image)} />
								</ImageWrapper>
							)
						})}
				</ImagesWrapper>
			</Body>
			<Footer>
				<Button
					type='button'
					variant='secondary'
					onClick={() => fileInputRef.current?.click()}
					isBlocked>
					Add photos
				</Button>
				<Button type='button' onClick={closePhotoManager} isBlocked>
					Done
				</Button>
			</Footer>
		</PhotoManagerCard>
	)
}

const PhotoManagerCard = styled(Card)`
	max-width: 600px;
`

const Back = styled.button`
	align-items: center;
	background-color: transparent;
	cursor: pointer;
	display: flex;
	height: 35px;
	justify-content: center;
	margin-right: 12px;
	width: 35px;
`

const ImagesWrapper = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	min-height: 240px;
`

const Delete = styled.button`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral004};
	border-radius: 32px;
	color: ${(props) => props.theme.colors.danger};
	cursor: pointer;
	display: flex;
	flex: none;
	height: 32px;
	justify-content: center;
	margin: 0;
	padding: 0;
	width: 32px;
	position: absolute;
	top: 12px;
	right: 12px;

	&:hover {
		background-color: ${(props) => props.theme.colors.danger};
		color: ${(props) => props.theme.colors.neutral001};
	}
	&:active {
		opacity: 0.85;
	}

	& > svg {
		width: 18px;
		height: 18px;
	}
`

const ImageWrapper = styled.div`
	flex: 1 1 1;
	width: 33.33%;
	height: 160px;
	position: relative;
	display: flex;
	border-radius: 7px;
	align-items: center;
	padding: 5px;
	& > img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: top center;
		border-radius: 7px;
	}
`

const ClearPhotosText = styled.span`
	font-size: 14px;
	font-weight: 600;
	width: 100%;
	white-space: nowrap;
`

const ClearPhotos = styled.button`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border: 2px solid ${(props) => props.theme.colors.neutral004};
	border-radius: 7px;
	color: ${(props) => props.theme.colors.danger};
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	transition: width 250ms ease-in-out;
	z-index: 100;
	padding: 12px;

	&:hover {
		background-color: ${(props) => props.theme.colors.neutral004};
	}
	&:active {
		background-color: ${(props) => props.theme.colors.neutral005};
	}
`

const ClearPhotosIcon = styled.span`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 5px;
	& > svg {
		width: 18px;
		height: 18px;
	}
`

export default PhotoManager
