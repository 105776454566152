import { doc, DocumentData, DocumentSnapshot, setDoc } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { TimelineProps } from 'providers/timelines/types'
import { UserProps } from 'providers/users/types'

export const formatTimeline = (data: DocumentSnapshot<DocumentData>): TimelineProps => {
	const timelineData = data.data()

	const fomattedTimeline: TimelineProps = {
		id: data.id,
		image: timelineData?.image,
		name: timelineData?.name,
		description: timelineData?.description,
		createdAt: timelineData?.createdAt?.toDate(),
		createdBy: {
			createdAt: new Date(),
			displayName: '',
			email: '',
			firstname: '',
			id: '',
			isLoading: undefined,
			isVerified: false,
			language: '',
			lastname: '',
			role: 0,
			status: '',
			currentTimeline: null,
			sharedTimelines: []
		},
		invitees: timelineData?.invitees,
		updatedAt: new Date()
	}
	return fomattedTimeline
}

export const limitString = (str: string, limit: number) => {
	if (str.length > limit) {
		return str.substring(0, limit) + '...'
	}
	return str
}

export type SetCurrentTimelineProps = {
	timeline: TimelineProps
	userId: string
	onUpdate?: (props?: any) => void
	updated?: (props?: any) => void
}

export const setCurrentTimeline = async ({
	timeline,
	userId,
	onUpdate,
	updated
}: SetCurrentTimelineProps) => {
	onUpdate && onUpdate()
	const userRef = doc(db, 'users', userId)
	const timelineRef = doc(db, 'timelines', timeline.id)

	const formattedCreatedBy = {
		createdAt: timeline.createdBy.createdAt,
		displayName: timeline.createdBy.displayName,
		email: timeline.createdBy.email,
		firstname: timeline.createdBy.firstname,
		id: timeline.createdBy.id,
		language: timeline.createdBy.language,
		lastname: timeline.createdBy.lastname,
		role: timeline.createdBy.role,
		status: timeline.createdBy.status
	}
	await setDoc(
		userRef,
		{
			currentTimeline: {
				id: timeline.id,
				name: timeline.name,
				description: timeline.description,
				createdAt: timeline.createdAt,
				createdBy: formattedCreatedBy,
				invitees: timeline.invitees ? timeline.invitees : [],
				updatedAt: timeline.updatedAt,
				ref: timelineRef
			}
		},
		{ merge: true }
	)

	updated && updated({ userRef, timelineRef })
}
