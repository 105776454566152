import Button from 'components/element/button'
import Input from 'components/element/input'
import Layout from 'components/layouts/site'
import {
	createUserWithEmailAndPassword,
	sendEmailVerification,
	signInWithEmailAndPassword,
	updateProfile
} from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { auth, db } from 'firebaseConfig'
import { ReactComponent as LogoIcon } from 'images/logo.svg'
import {
	EntryLink,
	Error,
	ErrorAction,
	ErrorMessage,
	Form,
	LoginErrorProps,
	Logo,
	Text,
	Title,
	Wrapper
} from 'pages/login'
import { FormEvent, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

const Signup = () => {
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [firstname, setFirstname] = useState<string>('')
	const [lastname, setLastname] = useState<string>('')

	const [error, setError] = useState<LoginErrorProps>({ errorCode: '', message: '' })
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const navigate = useNavigate()

	const signUp = async (e: FormEvent) => {
		e.preventDefault()

		setIsLoading(true)
		setError({ errorCode: '', message: '' })

		try {
			const userCredentials = await createUserWithEmailAndPassword(auth, email, password)
			await signInWithEmailAndPassword(auth, email, password)

			const uid = userCredentials.user.uid
			const usersRef = doc(db, 'users', uid)

			const userPartial = {
				createdAt: new Date(),
				email,
				firstname,
				language: 'en',
				lastname,
				role: 0,
				status: '1'
			}
			//update display name
			await updateProfile(userCredentials.user, {
				displayName: `${firstname} ${lastname}`
			})

			//send email verification
			await sendEmailVerification(userCredentials.user)

			// update other DB user data
			await setDoc(usersRef, userPartial)

			//redirect to timeline after signup
			navigate('/timeline')
		} catch (error: any) {
			console.error(error)
			switch (error.code) {
				case 'auth/email-already-in-use':
					setError({
						errorCode: error.code,
						message: 'An account with this email already exists. '
					})
					break
				case 'auth/invalid-email':
					setError({
						errorCode: error.code,
						message: `Enter a valid email address.`
					})
					break
				case 'auth/internal-error':
					setError({
						errorCode: error.code,
						message: 'Fill in all the required fields correctly.'
					})
					break
			}

			setIsLoading(false)
		}
	}

	return (
		<Layout>
			<Wrapper>
				<Logo>
					<LogoIcon />
				</Logo>
				<Title>Signup</Title>
				<Text>Enter your chosen email and password</Text>
				<form onSubmit={signUp}>
					<Form>
						<Input
							type='text'
							value={firstname}
							label='Firstname'
							onChange={(e) => setFirstname(e.currentTarget.value)}
						/>
						<Input
							type='text'
							value={lastname}
							label='Lastname'
							onChange={(e) => setLastname(e.currentTarget.value)}
						/>
						<Input
							type='text'
							value={email}
							label='Email'
							onChange={(e) => setEmail(e.currentTarget.value)}
						/>
						<Input
							type='password'
							value={password}
							label='Password'
							onChange={(e) => setPassword(e.currentTarget.value)}
						/>
						<Text>
							By signing up, I have read and agree to the{' '}
							<Link to='/'>Terms and conditions.</Link>
						</Text>
						{error.message && (
							<Error>
								<ErrorMessage>{error.message}</ErrorMessage>
								{error.errorCode === 'auth/email-already-in-use' && (
									<>
										{' '}
										Would you like to <ErrorAction to='/login'>Login?</ErrorAction>
									</>
								)}
							</Error>
						)}
						<Button type='submit' isLoading={isLoading}>
							Signup
						</Button>
						<EntryLink>
							Already have an account? <Link to='/login'>Login</Link>
						</EntryLink>
					</Form>
				</form>
			</Wrapper>
		</Layout>
	)
}

export default Signup
