import Button from 'components/element/button'
import DateTimePicker from 'components/element/dateTimePicker'
import Input from 'components/element/input'
import ProgressBar from 'components/element/progressBar'
import Textarea from 'components/element/textarea'
import { ReactComponent as CheckIcon } from 'icons/checkCircle.svg'
import { ReactComponent as ImagesIcon } from 'icons/images.svg'
import { ReactComponent as CloseIcon } from 'icons/times.svg'
import { map } from 'lodash'
import { transparentize } from 'polished'
import { ChangeEvent, FC, RefObject } from 'react'
import styled, { useTheme } from 'styled-components/macro'

export type MomentFormProps = {
	date: Date
	description: string
	fileInputRef: RefObject<HTMLInputElement>
	imageFiles: Array<File>
	isLoading: boolean
	isSaved: boolean
	isVisible: boolean
	progressBarWith: number
	title: string
	closeModal: () => void
	insertPhotos: () => void
	openPhotoManager: () => void
	setDate: (props: Date) => void
	setDescription: (props: string) => void
	setImageFiles: (props: Array<File>) => void
	setTitle: (props: string) => void
}

const MomentForm: FC<MomentFormProps> = ({
	date,
	description,
	fileInputRef,
	imageFiles,
	isLoading,
	isSaved,
	isVisible,
	progressBarWith,
	title,
	closeModal,
	insertPhotos,
	openPhotoManager,
	setDate,
	setDescription,
	setImageFiles,
	setTitle
}) => {
	const theme = useTheme()
	return (
		<Card isVisible={isVisible}>
			<CardWrapper>
				<Header>
					<Title>Post a moment</Title>
					<Close onClick={closeModal}>
						<CloseIcon />
					</Close>
				</Header>
				<Body>
					{!Boolean(imageFiles.length) && (
						<AddPhoto onClick={() => insertPhotos()}>
							<AddPhotoIcon>
								<ImagesIcon />
							</AddPhotoIcon>
							<AddPhotoText> Upload photos</AddPhotoText>
						</AddPhoto>
					)}
					{Boolean(imageFiles?.length) && (
						<ImagePreviews onClick={openPhotoManager}>
							{map(imageFiles, (image, index) => {
								if (index > imageFiles.length - 4) {
									return (
										<ImagePreview
											key={index}
											index={imageFiles.length - index - 1}
											src={URL.createObjectURL(image)}
										/>
									)
								}
								return <></>
							})}
							<Count>{imageFiles.length}</Count>
						</ImagePreviews>
					)}
					{isLoading && <ProgressBar width={progressBarWith ? progressBarWith : 0} />}
					<ImagePreviewAction>
						<Button type='button' variant='secondary' onClick={insertPhotos} isBlocked>
							Add photos
						</Button>
						<Button
							type='button'
							variant='secondary'
							onClick={openPhotoManager}
							disabled={!Boolean(imageFiles?.length)}
							isBlocked>
							Edit photos
						</Button>
					</ImagePreviewAction>
					<Input
						label='Title'
						labelBackground={theme.colors.neutral001}
						placeholder={`What's this moment all about?`}
						value={title}
						onChange={(e) => setTitle(e.currentTarget.value)}
						autoFocus
						isBlocked
					/>
					<Textarea
						onChange={(e: any) => {
							setDescription(e.currentTarget.value)
						}}
						label='Description'
						labelBackground={theme.colors.neutral001}
						placeholder='Tell us more about this moment'
						value={description}
						isBlocked
					/>
					<DateTimePicker
						label='Date'
						labelBackground={theme.colors.neutral001}
						value={date}
						onChange={setDate}
						width='240px'
					/>

					<FileInput
						ref={fileInputRef}
						key={Date.now()}
						type='file'
						name='file'
						accept='image/gif image/png, image/jpeg, image/jpg'
						onChange={(e: ChangeEvent<HTMLInputElement>) => {
							const files = e.currentTarget.files

							if (files) {
								setImageFiles([...imageFiles, ...Array.from(files)])
							}
						}}
						multiple
					/>
				</Body>
				<Footer>
					{!isSaved ? (
						<Button type='submit' isLoading={isLoading} disabled={isLoading} isBlocked>
							Post moment
						</Button>
					) : (
						<Button icon={<CheckIcon />} emphasis isBlocked>
							Moment posted
						</Button>
					)}
				</Footer>
			</CardWrapper>
		</Card>
	)
}

const Card = styled.div<{ isVisible: boolean }>`
	align-items: center;
	align-self: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	bottom: 0;
	display: ${(props) => (props.isVisible ? 'flex' : 'none')};
	flex-direction: column;
	justify-self: center;
	max-height: calc(95vh);
	max-width: 600px;
	overflow: hidden;
	position: absolute;
	width: 100%;
	width: 100%;
	z-index: ${(props) => props.theme.indexes.modal};
	@media screen and (min-width: 768px) {
		border-radius: 12px;
		bottom: unset;
	}
`

const CardWrapper = styled.div`
	width: 100%;
	overflow: auto;
`

const Header = styled.div`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral005};
	display: flex;
	width: 100%;
	padding: 24px 12px;
	@media screen and (min-width: 768px) {
		padding: 24px 36px;
	}
`

const Title = styled.div`
	font-weight: 600;
	font-size: 20px;
	line-height: 25px;
	margin-right: auto;
`

const Close = styled.button`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral005};
	border-radius: 30px;
	cursor: pointer;
	display: flex;
	height: 30px;
	justify-content: center;
	text-align: center;
	width: 30px;
`

const Body = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	width: 100%;
	& > * {
		margin-bottom: 12px;
	}

	padding: 24px 12px;
	@media screen and (min-width: 768px) {
		padding: 32px 36px;
	}
`

export const AddPhotoIcon = styled.span`
	transition: transform 250ms ease-in-out;
	transform-origin: bottom left;
`

export const AddPhotoText = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 18px;
	font-weight: 600;
	margin-left: 12px;
`

export const AddPhoto = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral005};
	border-radius: 12px;
	color: ${(props) => props.theme.colors.neutral003};
	display: flex;
	justify-content: center;
	height: 250px;
	margin-bottom: 12px;
	width: 100%;
	cursor: pointer;

	&:hover {
		${AddPhotoIcon} {
			transform: rotate(-10deg);
		}

		${AddPhotoText} {
			opacity: 0.75;
		}
	}
	@media screen and (min-width: 768px) {
		height: 320px;
	}
`

const FileInput = styled.input`
	display: none;
`

const ImagePreviews = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 250px;
	justify-content: center;
	margin: 12px 0 24px 0;
	position: relative;
	width: 100%;
	@media screen and (min-width: 768px) {
		height: 320px;
		margin-bottom: 32px;
		margin-top: 24px;
	}
`

const ImagePreview = styled.img<{ index: number }>`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border: 7px solid ${(props) => props.theme.colors.neutral001};
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	height: 250px;
	left: 0;
	margin-bottom: 10px;
	margin-right: 10px;
	object-fit: cover;
	object-position: top center;
	position: absolute;
	top: 0;
	transform: scale(${(props) => props.index * -0.05 + 1})
		translateY(${(props) => (props.index - 1) * 25}px);
	width: 100%;
	@media screen and (min-width: 768px) {
		height: 320px;
	}
`

const Count = styled.span`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 30px;
	bottom: 48px;
	display: flex;
	font-size: 18px;
	font-weight: 600;
	height: 30px;
	justify-content: center;
	position: absolute;
	right: 24px;
	width: 30px;
	z-index: 100;
`

const ImagePreviewAction = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 24px;
	width: 100%;
	& > * {
		margin-right: 12px;
		&:last-child {
			margin-right: 0;
		}
	}
`

const Footer = styled.div`
	align-items: center;
	display: flex;
	padding: 12px;
	width: 100%;
	margin-bottom: 120px;
	@media screen and (min-width: 768px) {
		margin-bottom: 0;
		padding: 20px 36px 36px 36px;
	}
`

export default MomentForm
