import { ReactComponent as CloseIcon } from 'icons/times.svg'
import { isNil } from 'lodash'
import { FC, FormEvent, ReactNode, useEffect, useState } from 'react'
import styled, { css, useTheme } from 'styled-components/macro'

export type InputProps = {
	autoComplete?: string
	autoFocus?: boolean
	disabled?: boolean
	error?: string
	hasClear?: boolean
	isBlocked?: boolean
	label?: string
	labelBackground?: string
	list?: ReactNode
	maxlength?: number
	max?: number
	min?: number
	placeholder?: string
	pre?: string
	type?: 'text' | 'email' | 'number' | 'password'
	value: string | number
	width?: string
	onBlur?: (e: FormEvent<HTMLInputElement>) => void
	onClear?: (e: FormEvent<HTMLButtonElement>) => void
	onChange: (e: FormEvent<HTMLInputElement>) => void
	onFocus?: (e: FormEvent<HTMLInputElement>) => void
}

const Input: FC<InputProps> = ({
	autoComplete = 'on',
	autoFocus = false,
	disabled = false,
	error,
	hasClear,
	isBlocked = false,
	label,
	labelBackground,
	list,
	maxlength,
	max,
	min,
	placeholder,
	pre,
	type = 'text',
	value,
	width,
	onBlur,
	onClear,
	onChange,
	onFocus,
	...rest
}: InputProps) => {
	const theme = useTheme()

	const [isFocus, setIsFocus] = useState<boolean>(false)
	const [isListOpen, setIsListOpen] = useState<boolean>(false)
	const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false)
	const isPassword = type === 'password'

	useEffect(() => {
		if (isFocus) {
			setIsListOpen(true)
		} else {
			setTimeout(() => {
				setIsListOpen(false)
			}, 250)
		}
	}, [isFocus])

	return (
		<Wrapper
			{...rest}
			isBlocked={isBlocked}
			disabled={disabled}
			hasError={Boolean(error)}
			hasLabel={Boolean(label)}
			isFocus={isFocus}
			width={width}>
			<InputWrapper hasPre={Boolean(pre)}>
				{Boolean(pre) && <span>{pre}</span>}
				<InputEl
					autoComplete={autoComplete}
					autoFocus={autoFocus}
					disabled={disabled}
					hasPre={Boolean(pre)}
					maxLength={maxlength}
					max={max}
					min={min}
					placeholder={placeholder}
					type={isPassword && isPasswordVisible ? 'text' : type}
					value={value}
					onBlur={(e) => {
						setIsFocus(false)
						onBlur && onBlur(e)
					}}
					onChange={(e) => {
						onChange(e)
					}}
					onFocus={(e) => {
						setIsFocus(true)
						onFocus && onFocus(e)
					}}
				/>
			</InputWrapper>
			{isPassword && (
				<Toggle onClick={() => setIsPasswordVisible((isVisible) => !isVisible)}>
					{isPasswordVisible ? 'hide' : 'show'}
				</Toggle>
			)}
			{label && (
				<Label
					isActive={Boolean(`${value}`.trim()) || isFocus || Boolean(placeholder)}
					background={labelBackground ? labelBackground : theme.colors.neutral005}>
					{label}
				</Label>
			)}
			{hasClear && Boolean(value) && (
				<Clear onClick={onClear}>
					<CloseIcon />
				</Clear>
			)}
			<Error>{error}</Error>
			{isListOpen && !isNil(list) && <List>{list}</List>}
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	disabled: boolean
	isBlocked: boolean
	hasError: boolean
	hasLabel: boolean
	isFocus: boolean
	width?: string
}>`
	background: inherit;
	border-radius: 12px;
	border: 2px solid
		${(props) =>
			props.isFocus
				? props.hasError
					? props.theme.colors.danger
					: props.theme.colors.neutral000
				: props.hasError
				? props.theme.colors.danger
				: props.theme.colors.neutral003};
	display: flex;
	flex-direction: column-reverse;
	height: 50px;
	padding: 0;
	position: relative;
	text-align: left;
	transition: all 250ms ease-in-out;
	width: ${(props) => (props.width ? props.width : props.isBlocked ? '100%' : 'auto')};
	${(props) =>
		props.disabled &&
		css`
			opacity: 0.5;
		`}
`

const Toggle = styled.span`
	border-radius: 12px;
	cursor: pointer;
	font-size: 12px;
	font-weight: 600;
	letter-spacing: 0.04em;
	padding: 0 8px;
	position: absolute;
	right: 5px;
	text-transform: uppercase;
	top: 13px;
	z-index: 2;
	&:hover {
		background: ${(props) => props.theme.colors.neutral005};
	}
`

const Label = styled.label<{ isActive: boolean; background: string }>`
	background: ${(props) =>
		props.background ? props.background : props.theme.colors.neutral005};
	border-radius: 4px;
	color: ${(props) =>
		props.isActive ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	font-size: 18px;
	left: 10px;
	padding: 0 5px;
	pointer-events: none;
	position: absolute;
	top: 14px;
	transform-origin: left;
	transition: all 250ms ease-in-out;
	user-select: none;
	${(props) =>
		props.isActive &&
		css`
			top: -10px;
			transform: scale(0.8);
		`}
`

const InputWrapper = styled.div<{ hasPre: boolean }>`
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.colors.neutral003};
	font-family: ${(props) => props.theme.font.family.default};
	font-size: 18px;
	padding: ${(props) => (props.hasPre ? '0 0 0 11px' : '0')};
	margin: 0;
	overflow: hidden;
`
const InputEl = styled.input<{ hasPre: boolean }>`
	appearance: none;
	background: none;
	border-radius: 12px;
	color: ${(props) => props.theme.colors.neutral000};
	font-family: ${(props) => props.theme.font.family.default};
	font-size: 18px;
	outline: none;
	padding: ${(props) => (!props.hasPre ? '11px 15px' : '11px 15px 11px 0')};
	margin: 0;
	width: 100%;
	::-webkit-calendar-picker-indicator {
		display: none !important;
	}
`

const Error = styled.div`
	top: 50px;
	color: ${(props) => props.theme.colors.danger};
	font-size: 12px;
	font-weight: 600;
	left: 0;
	letter-spacing: 0.02em;
	position: absolute;
`

const Clear = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	font-size: 12px;
	position: absolute;
	right: 10px;
	top: 13px;
	z-index: 10;
	&:hover {
		opacity: 0.75;
	}
`

const List = styled.div`
	position: absolute;
	top: calc(100% + 5px);
	z-index: 1000;
	width: 100%;
`
export default Input
