import { useAuthState } from 'providers/auth'
import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import Loader from './loader'

export type PublicElementProps = {
	children: ReactNode
}

const PublicElement: FC<PublicElementProps> = ({ children }) => {
	const { user, isLoading } = useAuthState()

	if (isLoading) {
		return <Loader />
	}

	if (!isLoading && user) {
		return <Navigate to='/timeline' />
	}

	return <>{children}</>
}

export default PublicElement
