import Loader from 'components/block/loader'
import PhotoViewer from 'components/block/photoViewer'
import {
	collection,
	doc,
	DocumentData,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	QueryDocumentSnapshot,
	startAt,
	where
} from 'firebase/firestore'
import { db, storage } from 'firebaseConfig'
import { map } from 'lodash'
import { DateTime } from 'luxon'
import { transparentize } from 'polished'
import { MomentProps } from 'providers/moments/types'
import { TimelineProps } from 'providers/timelines/types'
import { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Navigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { formatTimeline } from 'utils'
import Moment from './moment'
import decor from 'images/decor.svg'
import { ReactComponent as LogoIcon } from 'images/logo.svg'
import { getDownloadURL, ref } from 'firebase/storage'

const Timeline = () => {
	const FETCH_LENGTH = 20
	const style = `
		@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');
		
		.customFont {
			color: #F3876F;
			font-family: 'Pacifico', cursive;
		}

		.customButton {
			background: #7CA7B9;
			border: 2px solid #FFFFFF;
			box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
			border-radius: 60px;
			width: 200px;
		}

		@media screen and (min-width: 768px) {
			.customButton {
				border: 7px solid #FFFFFF;
			}
		}
	`

	const { timelineId, userId } = useParams()

	const [moments, setMoments] = useState<Array<MomentProps>>([])
	const [timeline, setTimeline] = useState<TimelineProps>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [hasMore, setHasMore] = useState<boolean>(false)

	const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>()
	const [isPhotoViewerOpen, setIsPhotoViewerOpen] = useState<boolean>(false)
	const [photos, setCurrentPhotos] = useState<Array<string>>()
	const [timelineImage, setTimelineImage] = useState<string>('')
	const [page, setPage] = useState<number>(0)

	useEffect(() => {
		const fn = async () => {
			if (!timelineId || !userId) {
				return
			}

			setIsLoading(true)

			try {
				const momentsRef = collection(db, 'moments')
				const timelineRef = doc(db, 'timelines', timelineId)

				const momentsQuery =
					lastVisible && lastVisible.data().timeline.id === timelineId
						? query(
								momentsRef,
								orderBy('date', 'desc'),
								where('timeline', '==', timelineRef),
								startAt(lastVisible),
								limit(FETCH_LENGTH)
						  )
						: query(
								momentsRef,
								orderBy('date', 'desc'),
								where('timeline', '==', timelineRef),
								limit(FETCH_LENGTH)
						  )

				const timelineSnapshot = await getDoc(timelineRef)
				const momentsSnapshot = await getDocs(momentsQuery)

				const timelineData: TimelineProps = formatTimeline(timelineSnapshot)
				const newMoments: Array<MomentProps> = map(momentsSnapshot.docs, (doc) => {
					return {
						createdAt: doc.data().createdAt.toDate(),
						description: doc.data().description,
						date: doc.data().date.toDate(),
						id: doc.id,
						images: doc.data().images,
						title: doc.data().title,
						timeline: doc.data().timeline,
						updatedAt: doc.data().updatedAt.toDate()
					}
				})

				setTimeline(timelineData)
				setLastVisible(momentsSnapshot.docs[momentsSnapshot.docs.length - 1])

				if (lastVisible && lastVisible.data().timeline.id === timelineId) {
					newMoments.shift()
					setMoments([...moments, ...newMoments])
				} else {
					setMoments(newMoments)
				}

				setHasMore(momentsSnapshot.docs.length === FETCH_LENGTH)
				setIsLoading(false)
			} catch (error: any) {
				//catch error
				console.error(error)
				setIsLoading(false)
			}
		}

		fn()

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, timelineId])

	useEffect(() => {
		const fn = async () => {
			setIsLoading(true)

			try {
				if (timeline?.image) {
					const imageRef = ref(storage, `timelines/${userId}/${timeline.image}`)
					const image = await getDownloadURL(imageRef)

					setTimelineImage(image)
				}
				setIsLoading(false)
			} catch (error: any) {
				setIsLoading(false)
				console.error(error)
			}
		}
		fn()
	}, [timeline, userId])

	if (!timelineId || !userId) {
		return <Navigate to='/404' />
	}

	return (
		<>
			{photos && Boolean(photos?.length) && (
				<PhotoViewer
					isVisible={isPhotoViewerOpen}
					photos={photos}
					onClose={setIsPhotoViewerOpen}
				/>
			)}

			<Wrapper background={decor}>
				<Details>
					{timelineImage && <Image src={timelineImage} />}
					<Info>
						<Name className='customFont'>{timeline?.name}</Name>
						<Description>{timeline?.description}</Description>
					</Info>
				</Details>

				<InfiniteScroll
					next={() => {
						hasMore && setPage((page) => page + 1)
					}}
					hasMore={hasMore}
					dataLength={moments.length}
					loader={undefined}>
					{Boolean(moments.length) &&
						map(moments, (moment: MomentProps, index) => (
							<MomentYearWrapper key={index}>
								<Year>{DateTime.fromJSDate(moment.date).toFormat('yyyy')}</Year>
								<Moment
									index={index}
									userId={userId}
									id={moment.id}
									date={moment.date}
									onClick={(photos) => {
										setIsPhotoViewerOpen(true)
										setCurrentPhotos(photos)
									}}
									photos={moment.images}
									title={moment.title}
									description={moment.description}
								/>
							</MomentYearWrapper>
						))}
				</InfiniteScroll>
				<LogoWrapper>
					<LogoIcon />
				</LogoWrapper>

				{isLoading && <Loader type='fill' />}
				<style>{style}</style>
			</Wrapper>
		</>
	)
}

const Wrapper = styled.div<{ background: string }>`
	background-color: #fff6f6;
	background-image: url(${(props) => props.background});
	background-position: top center;
	background-repeat: no-repeat;
	background-size: auto;
	display: flex;
	flex-direction: column;
	backdrop-filter: blur(5px);
	& .infinite-scroll-component {
		overflow: unset !important;
	}
`

const Details = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: auto;
	justify-content: center;
	margin-top: 32px;
	padding: 12px;
	width: auto;
	@media screen and (min-width: 768px) {
		flex-direction: row;
		padding: 32px;
		height: 800px;
		margin-top: 0;
	}
`

const Image = styled.img`
	background-color: #ffffff;
	height: 200px;
	width: 200px;
	border-radius: 200px;
	object-fit: cover;
	filter: drop-shadow(0px 10px 25px rgba(0, 0, 0, 0.25));
	border: 5px solid #ffffff;
	@media screen and (min-width: 768px) {
		width: 480px;
		border: 12px solid #ffffff;
		height: 480px;
		border-radius: 480px;
	}
`

const Info = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	@media screen and (min-width: 768px) {
		margin-left: 48px;
		text-align: left;
	}
`

const Name = styled.div`
	border-radius: 7px;
	color: #f3876f;
	text-shadow: 3px 0 #fff, 0 3px #fff, -3px 0 #fff, 0 -3px #fff, 3px 3px #fff,
		-3px -3px #fff, 12px 14px 28px rgba(0, 0, 0, 0.25);
	font-weight: 400;
	font-size: 48px;
	line-height: normal;
	@media screen and (min-width: 768px) {
		text-shadow: 5px 0 #fff, 0 5px #fff, -5px 0 #fff, 0 -5px #fff, 5px 5px #fff,
			-5px -5px #fff, 12px 14px 28px rgba(0, 0, 0, 0.25);
		font-weight: 400;
		font-size: 96px;
	}
`

const Description = styled.div`
	font-weight: 300;
	font-size: 18px;
	line-height: 24px;
	@media screen and (min-width: 768px) {
		font-size: 24px;
		line-height: 30px;
	}
`

const MomentYearWrapper = styled.div`
	height: auto;
	@media screen and (min-width: 768px) {
		height: 1000px;
	}
`

const Year = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 7px;
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	display: flex;
	font-size: 24px;
	font-weight: 600;
	height: 48px;
	justify-content: center;
	margin-left: auto;
	margin-right: 24px;
	position: sticky;
	text-align: right;
	top: 40px;
	margin-top: 50px;
	width: 100px;
	z-index: 2;
	&::before {
		content: '';
		width: 38px;
		height: 1px;
		display: block;
		background-color: ${(props) => props.theme.colors.neutral004};
		margin-left: auto;
		margin-right: 180px;
		position: absolute;
	}
`

const LogoWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-top: 48px;
	& > svg {
		width: 240px;
	}
`

export default Timeline
