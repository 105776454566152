import { UserProps } from 'providers/users/types'

export enum MeActions {
	fetch = 'FETCH',
	reset = 'RESET',
	update = 'UPDATE',
	updateLoading = 'UPDATE_LOADING'
}

export type MeActionProps =
	| { type: MeActions.fetch; payload: UserProps }
	| { type: MeActions.update; payload: UserProps }
	| { type: MeActions.reset }
	| { type: MeActions.updateLoading; payload: boolean }
