import { DocumentReference } from 'firebase/firestore'

export enum MomentsActions {
	add = 'ADD',
	fetch = 'FETCH',
	fetchNew = 'FETCH_NEW',
	remove = 'REMOVE',
	update = 'UPDATE',
	updatehasMore = 'UPDATE_HAS_MORE',
	updateLoading = 'UPDATE_LOADING'
}

export type MomentsActionProps =
	| { type: MomentsActions.add; payload: MomentProps }
	| { type: MomentsActions.fetch; payload: MomentProps[] }
	| { type: MomentsActions.fetchNew; payload: MomentProps[] }
	| { type: MomentsActions.remove; payload: string }
	| { type: MomentsActions.update; payload: MomentProps }
	| { type: MomentsActions.updatehasMore; payload: boolean }
	| { type: MomentsActions.updateLoading; payload: boolean }

export type MomentProps = {
	createdAt: Date
	description: string
	date: Date
	id: string
	images: Array<string>
	title: string
	timeline: DocumentReference
	updatedAt: Date
}

export type MomentsStateProps = {
	data: Array<MomentProps>
	isLoading: boolean
	hasMore: boolean
}
