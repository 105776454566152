import { auth } from 'firebaseConfig'
import { onAuthStateChanged, User } from 'firebase/auth'
import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react'

export type AuthStateProps = {
	user: User | null
	isLoading: boolean
}

export const initialMeState: AuthStateProps = {
	user: null,
	isLoading: true
}

export const AuthState = createContext(initialMeState)
export const useAuthState = () => useContext(AuthState)

export const AuthProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [authState, setAuthState] = useState(initialMeState)

	useEffect(() => {
		setAuthState({
			...authState,
			isLoading: true
		})

		//check login status
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setAuthState({
					user,
					isLoading: false
				})
			} else {
				setAuthState({
					user: null,
					isLoading: false
				})
			}
		})

		return () => unsubscribe()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return <AuthState.Provider value={authState}>{children}</AuthState.Provider>
}
