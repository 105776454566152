import { FC } from 'react'
import styled from 'styled-components/macro'

export type ProgresBarProps = {
	width: number
}

const ProgressBar: FC<ProgresBarProps> = ({ width }) => {
	return (
		<Wrapper>
			<Progress width={width}></Progress>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral005};
	border-radius: 3px;
	flex: 1;
	height: 10px;
	width: 100%;
	margin-bottom: 24px;
`

const Progress = styled.div<{ width: number }>`
	background-color: ${(props) => props.theme.colors.primary};
	border-radius: 3px;
	height: 10px;
	width: ${(props) => props.width}%;
`

export default ProgressBar
