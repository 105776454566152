import { FC } from 'react'
import styled from 'styled-components/macro'

export type ThemeProps = {
	id: string
	accent: string
	primary: string
	title: string
}

type ThemeComponentProps = {
	isActive: boolean
	theme: ThemeProps
	onClick: () => void
}
const Theme: FC<ThemeComponentProps> = ({
	theme: { accent, primary, title },
	isActive,
	onClick
}) => {
	return (
		<Wrapper onClick={onClick}>
			<Outer isActive={isActive} color={primary}>
				<Inner color={accent} />
			</Outer>
			<Title isActive={isActive}>{title}</Title>
		</Wrapper>
	)
}
const Wrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-right: 20px;
`

const Outer = styled.div<{ color: string; isActive: boolean }>`
	align-items: center;
	background: ${(props) => props.color};
	border-radius: 7px;
	border: ${(props) => (props.isActive ? '4px' : '2px')} solid
		${(props) => props.theme.colors.neutral000};
	cursor: pointer;
	display: flex;
	height: 80px;
	justify-content: center;
	width: 80px;
	&:hover {
		opacity: 0.75;
	}
`

const Inner = styled.div<{ color: string }>`
	background: ${(props) => props.color};
	border-radius: 7px;
	height: 30px;
	width: 30px;
`

const Title = styled.span<{ isActive: boolean }>`
	color: ${(props) =>
		props.isActive ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	font-weight: 600;
	font-size: 14px;
	margin-top: 5px;
`

export default Theme
