import Button from 'components/element/button'
import { FC } from 'react'
import Modal, { Footer, Body, Header, Text, Title, Card, Close } from './modal'
import { ReactComponent as CloseIcon } from 'icons/times.svg'

export type DeleteConfirmationProps = {
	isOpen: boolean
	isLoading?: boolean
	itemToBeDelete?: string
	setIsOpen: (props: boolean) => void
	onDelete: (props?: any) => void
}

const DeleteConfirmation: FC<DeleteConfirmationProps> = ({
	isOpen,
	isLoading = false,
	itemToBeDelete = 'data',
	setIsOpen,
	onDelete
}) => {
	const onCancel = () => setIsOpen(false)

	return (
		<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
			<Card>
				<Header>
					<Title>{`Would you like to delete this ${itemToBeDelete}?`}</Title>
					<Close onClick={onCancel}>
						<CloseIcon />
					</Close>
				</Header>
				<Body>
					<Text>This action cannot be undone. Press continue to proceed</Text>
				</Body>
				<Footer>
					<Button variant='primary' onClick={onCancel} isBlocked>
						Cancel
					</Button>
					<Button variant='secondary' isLoading={isLoading} onClick={onDelete} isBlocked>
						Continue
					</Button>
				</Footer>
			</Card>
		</Modal>
	)
}

export default DeleteConfirmation
