import { useAuthState } from 'providers/auth'
import { useFetchMe } from 'providers/me/useFetchMe'
import { useFetchTimelines } from 'providers/timelines/useFetchTimelines'
import { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import Loader from './loader'

export type PrivateElementProps = {
	children: ReactNode
}

const PrivateElement: FC<PrivateElementProps> = ({ children }) => {
	const { user, isLoading } = useAuthState()
	useFetchMe()
	useFetchTimelines()

	if (isLoading) {
		return <Loader />
	}

	if (!isLoading && !user) {
		return <Navigate to='/login' />
	}

	return <>{children}</>
}

export default PrivateElement
