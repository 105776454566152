import Context, { ContextMenuItemType } from 'components/block/contextMenu'
import { blink } from 'components/keyframes'
import { signOut } from 'firebase/auth'
import { auth } from 'firebaseConfig'
import { ReactComponent as AppearanceIcon } from 'icons/appearance.svg'
import { ReactComponent as LogoIcon } from 'icons/logoIcon.svg'
import { ReactComponent as SettingsIcon } from 'icons/settings.svg'
import { ReactComponent as TimelineIcon } from 'icons/timeline.svg'
import { transparentize } from 'polished'
import { useMeState } from 'providers/me'
import { FC, useState } from 'react'
import { Link, NavLink as SideNavLink, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const SideNavigation: FC = () => {
	const navigate = useNavigate()

	const me = useMeState()

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
	const menu: Array<ContextMenuItemType> = [
		{
			label: 'Profile',
			onClick: () => {}
		},
		{
			label: 'Manage timelines',
			onClick: () => {
				navigate('/timelines')
			}
		},
		{
			label: 'Settings',
			onClick: () => {}
		},
		{
			label: 'Logout',
			onClick: () => logout(),
			isDanger: true
		}
	]

	const onMenuClose = () => setIsMenuOpen(false)

	const logout = async () => {
		try {
			await signOut(auth)
			navigate('/login')
		} catch (error: any) {
			console.error(error)
		}
	}

	return (
		<Wrapper>
			<Logo to='/'>
				<LogoIcon />
			</Logo>
			<NavLinks>
				<NavLink to='/timeline'>
					<TimelineIcon />
					<NavLinkText>Timeline</NavLinkText>
				</NavLink>
				<NavLink to='/appearance'>
					<AppearanceIcon />
					<NavLinkText>Appearance</NavLinkText>
				</NavLink>
				<NavLink to='/settings'>
					<SettingsIcon />
					<NavLinkText>Settings</NavLinkText>
				</NavLink>
				{me?.isLoading ? (
					<User isBlinking>
						<UserIcon />
						<NavLinkText>user</NavLinkText>
					</User>
				) : (
					<UserContext
						toggle={
							<User onClick={() => setIsMenuOpen((isOpen) => !isOpen)}>
								<UserIcon>{`${me.firstname.charAt(0)}${me.lastname.charAt(0)}`}</UserIcon>
								<NavLinkText>{me.firstname}</NavLinkText>
							</User>
						}
						menu={menu}
						isVisible={isMenuOpen}
						onClose={onMenuClose}
						position={{
							bottom: 'calc(100% + 12px)',
							right: '12px'
						}}
					/>
				)}
			</NavLinks>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-right: 1px solid ${(props) => props.theme.colors.neutral005};
	display: flex;
	flex-direction: column;
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: ${(props) => props.theme.indexes.fixed};

	@media screen and (min-width: 768px) {
		height: 100vh;
		width: auto;
		display: flex;
		top: 0;
	}
`

const Logo = styled(Link)`
	align-items: center;
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral005};
	display: none;
	font-size: 32px;
	font-weight: 800;
	height: 71px;
	justify-content: center;
	padding: 12px;
	width: 100px;
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

const NavLinks = styled.div`
	align-items: center;
	box-shadow: 0 -3px 8px ${(props) => transparentize(0.95, props.theme.colors.neutral000)};
	display: flex;
	flex: 1;
	justify-content: space-around;
	width: 100%;
	@media screen and (min-width: 768px) {
		box-shadow: none;
		justify-content: center;
		flex-direction: column;
	}
`

const NavLinkText = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	display: none;
	font-size: 13px;
	letter-spacing: 0.02em;
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

const NavLink = styled(SideNavLink)<{ mobileOnly?: boolean }>`
	align-items: center;
	background: none;
	color: ${(props) => props.theme.colors.neutral000};
	cursor: pointer;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border-bottom: 3px solid transparent;
	padding: 12px;
	flex: 1;
	height: 70px;
	& > svg {
		height: 25px;
	}

	&.active {
		border-bottom: 3px solid ${(props) => props.theme.colors.neutral004};
		color: ${(props) => props.theme.colors.primary};

		${NavLinkText} {
			color: ${(props) => props.theme.colors.primary};
		}
	}

	&:hover {
		border-bottom: 3px solid ${(props) => props.theme.colors.neutral005};
		opacity: 0.75;
	}

	@media screen and (min-width: 768px) {
		display: ${(props) => (props.mobileOnly ? 'none' : 'flex')};
		align-items: center;
		height: 100px;
		width: 100px;
		border-bottom: none;
		padding: 0;
		justify-content: center;
		flex: 0 auto;
		& > svg {
			height: auto;
		}

		&.active {
			border-bottom: none;
			background-color: ${(props) => props.theme.colors.neutral004};
			color: ${(props) => props.theme.colors.primary};

			${NavLinkText} {
				color: ${(props) => props.theme.colors.primary};
			}
		}

		&:hover {
			border-bottom: none;
			background-color: ${(props) => props.theme.colors.neutral005};
			opacity: 0.75;
		}
	}
`

const User = styled.div<{ isBlinking?: boolean }>`
	align-items: center;
	cursor: pointer;
	display: flex;
	flex-direction: column;
	height: 70px;
	justify-content: center;
	flex: 1;
	${(props) =>
		props.isBlinking &&
		css`
			animation: ${blink} 1s infinite;
		`}
	&:hover {
		opacity: 0.75;
	}

	@media screen and (min-width: 768px) {
		display: none;
	}
`

const UserContext = styled(Context)`
	flex: 1 1 auto;
	@media screen and (min-width: 768px) {
		display: none;
	}
`

const UserIcon = styled.div`
	align-items: center;
	border-radius: 25px;
	border: 3px solid ${(props) => props.theme.colors.neutral000};
	display: flex;
	font-size: 11px;
	font-weight: 600;
	height: 25px;
	justify-content: center;
	text-transform: uppercase;
	width: 25px;
	text-align: center;
	padding-left: 1px;
`

export default SideNavigation
