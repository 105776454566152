import { ReactComponent as EllipsesVertical } from 'icons/ellipsesVertical.svg'
import { lighten } from 'polished'
import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components/macro'
import Context, { ContextMenuItemType } from '../contextMenu'

export type ItemProps = {
	data: any
	format: (props: any) => ReactNode
	onClick?: (props: any) => void
	menu?: Array<ContextMenuItemType>
}

const Item: FC<ItemProps> = ({ data, format, onClick, menu }) => {
	const [isVisible, setIsVisible] = useState<boolean>(false)

	const onClose = () => setIsVisible(false)
	const onToggle = () => setIsVisible((isVisible) => !isVisible)

	return (
		<Wrapper>
			<Data onClick={() => onClick && onClick(data)}>{format(data)}</Data>

			{/* TODO: Add menu to list */}
			<Context
				data={data}
				isVisible={isVisible}
				menu={menu ? menu : []}
				toggle={
					<MenuToggle onClick={onToggle}>
						<EllipsesVertical />
					</MenuToggle>
				}
				onClose={onClose}
				position={{
					top: 'calc(100% + 10px)',
					right: '0'
				}}
			/>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral005};
	display: flex;
	padding: 12px;
	width: 100%;
	&:hover {
		background-color: ${(props) => lighten(0.4, props.theme.colors.neutral003)};
	}

	&:active {
		background-color: ${(props) => lighten(0.45, props.theme.colors.neutral003)};
	}
	&:first-child {
		border-top-right-radius: 12px;
		border-top-left-radius: 12px;
	}

	&:last-child {
		border-bottom-right-radius: 12px;
		border-bottom-left-radius: 12px;
	}
`

const Data = styled.div`
	flex: 1;
	cursor: pointer;
`
export const Label = styled.span`
	font-size: 16px;
	font-weight: 600;
	margin-right: auto;
	margin-left: 10px;
	height: 32px;
	display: flex;
	align-items: center;
`

const MenuToggle = styled.div`
	align-items: center;
	border-radius: 7px;
	cursor: pointer;
	display: flex;
	height: 40px;
	justify-content: center;
	width: 40px;
	&:hover {
		background-color: ${(props) => props.theme.colors.neutral004};
		opacity: 0.75;
	}
	&:active {
		background-color: ${(props) => props.theme.colors.neutral004};
		opacity: 0.5;
	}
`

export default Item
