import Loader from 'components/block/loader'
import { isNil, map } from 'lodash'
import { transparentize } from 'polished'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

export type DatalistProps = {
	id: string
	list?: Array<any>
	option: (data: any, index: number) => ReactNode
	isLoading: boolean
}

const Datalist: FC<DatalistProps> = ({ id, list, option, isLoading }) => {
	if (isLoading) {
		return (
			<Wrapper>
				<Loader type='spinnerOnly' />
			</Wrapper>
		)
	}

	if (!isNil(list) && !Boolean(list.length)) {
		return (
			<Wrapper>
				<Empty>no data found</Empty>
			</Wrapper>
		)
	}

	if (list) {
		return <Wrapper id={id}>{map(list, option)}</Wrapper>
	}

	return <></>
}

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	font-size: 14px;
	height: auto;
	padding: 12px 0;
	width: 100%;
`

const Empty = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	padding: 12px;
`

export const DatalistOption = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral004};
	cursor: pointer;
	font-size: 16px;
	padding: 12px;
	&:hover {
		background-color: ${(props) => props.theme.colors.neutral004};
	}
	&:last-child {
		border-bottom: none;
	}
`

export default Datalist
