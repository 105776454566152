import {
	collection,
	doc,
	DocumentData,
	getDocs,
	limit,
	orderBy,
	query,
	QueryDocumentSnapshot,
	startAt,
	where
} from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { map } from 'lodash'
import { useMeState } from 'providers/me'
import { useEffect, useState } from 'react'
import { useMomentsDispatch } from '.'
import { MomentProps, MomentsActions } from './types'

export type UseFetchMomentProps = {
	userId?: string
	page: number
}

export const useFetchMoments = ({ userId, page }: UseFetchMomentProps) => {
	const FETCH_LENGTH = 20

	const momentDispatch = useMomentsDispatch()
	const me = useMeState()

	const [data, setData] = useState<Array<MomentProps>>()
	const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot<DocumentData>>()

	useEffect(() => {
		const fn = async () => {
			if (!me?.currentTimeline?.id) {
				return
			}

			const momentUserId = userId ? userId : me?.currentTimeline?.id

			try {
				momentDispatch({ type: MomentsActions.updateLoading, payload: true })

				const momentsRef = collection(db, 'moments')
				const currentTimelineRef = doc(db, 'timelines', momentUserId)

				const momentsQuery =
					lastVisible && lastVisible.data().timeline.id === momentUserId
						? query(
								momentsRef,
								orderBy('date', 'desc'),
								where('timeline', '==', currentTimelineRef),
								startAt(lastVisible),
								limit(FETCH_LENGTH)
						  )
						: query(
								momentsRef,
								orderBy('date', 'desc'),
								where('timeline', '==', currentTimelineRef),
								limit(FETCH_LENGTH)
						  )

				const momentsSnapshot = await getDocs(momentsQuery)
				setLastVisible(momentsSnapshot.docs[momentsSnapshot.docs.length - 1])

				const payload: Array<MomentProps> = map(momentsSnapshot.docs, (doc) => {
					return {
						createdAt: doc.data().createdAt.toDate(),
						description: doc.data().description,
						date: doc.data().date.toDate(),
						id: doc.id,
						images: doc.data().images,
						title: doc.data().title,
						timeline: doc.data().timeline,
						updatedAt: doc.data().updatedAt.toDate()
					}
				})

				if (lastVisible && lastVisible.data().timeline.id === momentUserId) {
					momentDispatch({
						type: MomentsActions.fetch,
						payload
					})
				} else {
					momentDispatch({
						type: MomentsActions.fetchNew,
						payload
					})
				}

				momentDispatch({
					type: MomentsActions.updatehasMore,
					payload: momentsSnapshot.docs.length === FETCH_LENGTH
				})
			} catch (error: any) {
				//catch error
				console.error(error)
				momentDispatch({ type: MomentsActions.updateLoading, payload: false })
			}
		}

		fn()

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, me?.id, me?.currentTimeline])

	return [data, setData]
}
