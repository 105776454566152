import { UserProps } from 'providers/users/types'
import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react'
import { reducer } from './reducer'
import { MeActionProps } from './types'

export const initialMeState: UserProps = {
	createdAt: new Date(),
	displayName: '',
	email: '',
	firstname: '',
	id: '',
	isLoading: true,
	isVerified: false,
	language: 'en',
	lastname: '',
	role: 0,
	status: '1',
	currentTimeline: null,
	sharedTimelines: []
}

export const MeState = createContext<UserProps>(initialMeState)
export const MeDispatch = createContext<Dispatch<MeActionProps>>(() => null)
export const useMeState = () => useContext(MeState)
export const useMeDispatch = () => useContext(MeDispatch)

export const MeProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [userState, userDispatch] = useReducer(
		reducer,
		initialMeState,
		() => initialMeState
	)

	return (
		<MeState.Provider value={userState}>
			<MeDispatch.Provider value={userDispatch}>{children}</MeDispatch.Provider>
		</MeState.Provider>
	)
}
