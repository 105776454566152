import Loader from 'components/block/loader'
import PhotoViewer from 'components/block/photoViewer'
import Dropdown from 'components/element/dropdown'
import Layout from 'components/layouts/main'
import { ReactComponent as ManageTimelinesIcon } from 'icons/manageTimelines.svg'
import { map } from 'lodash'
import { DateTime } from 'luxon'
import { useMeDispatch, useMeState } from 'providers/me'
import { MeActions } from 'providers/me/types'
import { useMomentsState } from 'providers/moments'
import { MomentProps } from 'providers/moments/types'
import { useFetchMoments } from 'providers/moments/useFetchMoments'
import { useTimelinesState } from 'providers/timelines'
import { useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { setCurrentTimeline } from 'utils'
import Form from './form'
import Moment from './moment'
import Post from './post'

const Moments = () => {
	const navigate = useNavigate()

	const { data: moments, isLoading, hasMore } = useMomentsState()
	const { data: timelines } = useTimelinesState()
	const me = useMeState()
	const meDispatch = useMeDispatch()

	const [isFormOpen, setIsFormOpen] = useState<boolean>(false)
	const [momentToEdit, setMomentToEdit] = useState<MomentProps | null>(null)

	const [isTimelineLoading, setIsTimelineLoading] = useState<boolean>(false)
	const [isPhotoViewerOpen, setIsPhotoViewerOpen] = useState<boolean>(false)
	const [photos, setCurrentPhotos] = useState<Array<string>>()
	const [page, setPage] = useState<number>(0)

	useFetchMoments({ userId: me.currentTimeline?.createdBy?.id, page })

	return (
		<Layout>
			<Wrapper>
				{photos && Boolean(photos?.length) && (
					<PhotoViewer
						isVisible={isPhotoViewerOpen}
						photos={photos}
						onClose={setIsPhotoViewerOpen}
					/>
				)}

				<Form
					isOpen={isFormOpen}
					moment={momentToEdit}
					onClose={() => setMomentToEdit(null)}
					setIsOpen={setIsFormOpen}
				/>

				<TimelineSelector>
					<Dropdown
						label={
							me?.currentTimeline?.name ? me.currentTimeline.name : 'Select Timeline'
						}
						isLoading={me.isLoading || isLoading || isTimelineLoading}
						menu={[
							{
								title: 'Timelines',
								items: map(timelines, (timeline) => {
									return {
										label: timeline.name,
										onClick: () => {
											setCurrentTimeline({
												timeline,
												userId: me.id,
												onUpdate: () => {
													setIsTimelineLoading(true)
												},
												updated: ({ timelineRef }) => {
													meDispatch({
														type: MeActions.fetch,
														payload: {
															...me,
															currentTimeline: {
																...timeline,
																ref: timelineRef
															}
														}
													})
													setIsTimelineLoading(false)
												}
											})
											navigate('/timeline')
											window.scrollTo(0, 0)
										}
									}
								})
							}
						]}
						action={{
							onClick: () => navigate('/timelines'),
							label: 'Manage timelines',
							icon: <ManageTimelinesIcon />
						}}
					/>
				</TimelineSelector>

				<Post onClick={() => setIsFormOpen(true)} />
				<Title>Moments</Title>

				{!isLoading && moments.length === 0 && <EmptyState>No moment yet.</EmptyState>}

				<InfiniteScroll
					next={() => {
						hasMore && setPage((page) => page + 1)
					}}
					hasMore={hasMore}
					dataLength={moments.length}
					loader={<></>}>
					{Boolean(moments.length) &&
						map(moments, (moment: MomentProps, index) => (
							<MomentWrapper key={index}>
								<Year>{DateTime.fromJSDate(moment.date).toFormat('yyyy')}</Year>
								<Moment
									id={moment.id}
									date={moment.date}
									onClick={(photos) => {
										setIsPhotoViewerOpen(true)
										setCurrentPhotos(photos)
									}}
									onEdit={() => {
										setMomentToEdit(moment)
										setIsFormOpen(true)
									}}
									photos={moment.images}
									title={moment.title}
									description={moment.description}
								/>
							</MomentWrapper>
						))}
				</InfiniteScroll>

				{isLoading && <Loader type='fill' />}
				{!hasMore && !isLoading && moments.length !== 0 && (
					<EmptyState>No more moments</EmptyState>
				)}
			</Wrapper>
		</Layout>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	max-width: 600px;
	margin: 0 auto;
	margin-bottom: 60px;
	& .infinite-scroll-component {
		overflow: unset !important;
	}

	@media screen and (min-width: 768px) {
		margin: 32px auto;
		& .infinite-scroll-component {
			margin-top: 26px;
		}
	}
`

const TimelineSelector = styled.div`
	padding: 12px;
	display: flex;
	width: 100%;
	@media screen and (min-width: 768px) {
		display: none;
	}
`
const Title = styled.div`
	display: none;
	@media screen and (min-width: 768px) {
		margin-bottom: 36px;
		margin-top: 32px;
		padding-bottom: 10px;
		border-bottom: 1px solid ${(props) => props.theme.colors.neutral004};
		font-size: 20px;
		font-weight: 600;
		line-height: 25px;
		display: flex;
	}
`

const EmptyState = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 20px;
	font-weight: 600;
	line-height: 25px;
	margin-bottom: 36px;
	margin-top: 32px;
	text-align: center;
`

const Year = styled.div`
	background: ${(props) => props.theme.colors.neutral005};
	font-size: 18px;
	font-weight: 600;
	padding: 12px;
	position: sticky;
	text-align: right;
	top: 51px;
	z-index: ${(props) => props.theme.indexes.sticky};

	@media screen and (min-width: 992px) {
		background: none;
		border: none;
		font-size: 24px;
		height: 24px;
		margin-right: -120px;
		top: 81px;
		padding: 0;
		&::after {
			content: '';
			width: 38px;
			height: 1px;
			display: block;
			background-color: ${(props) => props.theme.colors.neutral004};
			margin-top: -12px;
			margin-left: auto;
			margin-right: 72px;
		}
	}
`

const MomentWrapper = styled.div`
	padding-bottom: 24px;
`

export default Moments
