import DeleteConfirmation from 'components/block/deleteConfirmation'
import Loader from 'components/block/loader'
import Button from 'components/element/button'
import Checkbox from 'components/element/checkbox'
import Input from 'components/element/input'
import ProgressBar from 'components/element/progressBar'
import Textarea from 'components/element/textarea'
import Layout from 'components/layouts/main'
import { addDoc, collection, deleteDoc, doc, getDoc, setDoc } from 'firebase/firestore'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { db, storage } from 'firebaseConfig'
import { ReactComponent as BackIcon } from 'icons/backArrow.svg'
import { ReactComponent as CheckIcon } from 'icons/checkCircle.svg'
import { ReactComponent as ImagesIcon } from 'icons/images.svg'
import { isNil } from 'lodash'
import { DateTime } from 'luxon'
import { useMeState } from 'providers/me'
import { useTimelinesDispatch, useTimelinesState } from 'providers/timelines'
import { TimelineProps, TimelinesActions } from 'providers/timelines/types'
import { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { formatTimeline } from 'utils'

const TimelineForm = () => {
	const navigate = useNavigate()
	const { id } = useParams()

	const fileInputRef = useRef<HTMLInputElement>(null)
	const me = useMeState()
	const { isLoading } = useTimelinesState()
	const dispatch = useTimelinesDispatch()

	const [isEdit, setIsEdit] = useState<boolean>(false)
	const [name, setName] = useState<string>('')
	const [description, setDescription] = useState<string>('')
	const [selectedTimeline, setSelectedTimeline] = useState<TimelineProps | null>(null)
	const [isSaved, setIsSaved] = useState<boolean>(false)
	const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false)
	const [isFormLoading, setIsFormLoading] = useState<boolean>(true)
	const [imageFiles, setImageFiles] = useState<Array<File>>([])
	const [imageUrl, setImageUrl] = useState<string>('')
	const [uploadProgress, setUploadProgress] = useState<number>(0)

	useEffect(() => {
		const fn = async () => {
			if (!isNil(id)) {
				setIsFormLoading(true)
				setIsEdit(true)

				const selectedTimelineRef = doc(db, 'timelines', id)
				const timelineSnapshot = await getDoc(selectedTimelineRef)
				const formattedTimeline = formatTimeline(timelineSnapshot)

				setName(formattedTimeline.name)
				setDescription(formattedTimeline.description)

				if (formattedTimeline.image) {
					try {
						const imageRef = ref(
							storage,
							`timelines/${me.id}/${formattedTimeline?.image}`
						)
						const imageUrl = await getDownloadURL(imageRef)

						setImageUrl(imageUrl)
					} catch (error: any) {
						console.error(error)
					}
				}
				setSelectedTimeline(formattedTimeline)
			}
			setIsFormLoading(false)
		}

		fn()
	}, [id, me.id])

	const insertPhotos = () => fileInputRef.current?.click()

	const clearForm = () => {
		setName('')
		setDescription('')
	}

	const onSubmit = (e: FormEvent) => {
		e.preventDefault()
		isEdit ? editTimeline() : addTimeline()
	}

	const uploadImage = async (image: File) => {
		if (!image) {
			return
		}

		return new Promise(
			(resolve: (filename: string) => void, reject: (reason: any) => void) => {
				const imageNameArray = image?.name.split('.')
				const extension = imageNameArray[imageNameArray.length - 1]
				const date = DateTime.now()
				const filename: string = `${date.toISODate()}-${Date.now()}.${extension}`

				const timelineImageRef = ref(storage, `timelines/${me.id}/${filename}`)
				const uploadTask = uploadBytesResumable(timelineImageRef, image)

				uploadTask.on(
					'state_changed',
					(snapshot) => {
						const progress = Math.round(
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100
						)
						setUploadProgress(progress)
					},
					(error) => {
						alert(error)
						reject(error)
					},
					() => {
						resolve(filename)
					}
				)
			}
		)
	}

	const addTimeline = async () => {
		dispatch({ type: TimelinesActions.updateLoading, payload: true })

		const uploadedImage = await uploadImage(imageFiles[0])
		const image = uploadedImage ? uploadedImage : ''

		const createdAt = new Date()
		const updatedAt = createdAt
		const timeline = await addDoc(collection(db, 'timelines'), {
			createdBy: {
				id: me.id,
				firstname: me.firstname,
				lastname: me.lastname,
				email: me.email,
				ref: doc(db, 'users', me.id)
			},
			image,
			createdAt,
			updatedAt,
			name,
			description
		})

		const payload: TimelineProps = {
			id: timeline.id,
			image: image,
			name,
			description,
			createdAt,
			updatedAt,
			createdBy: me,
			invitees: []
		}

		dispatch({ type: TimelinesActions.add, payload })

		clearForm()
		setIsSaved(true)
		setTimeout(() => {
			setIsSaved(false)
			navigate('/timelines')
		}, 1000)
	}

	const editTimeline = async () => {
		if (selectedTimeline === null) {
			return
		}
		dispatch({ type: TimelinesActions.updateLoading, payload: true })

		const uploadedImage = await uploadImage(imageFiles[0])
		const image = uploadedImage ? uploadedImage : ''

		const updatedAt = new Date()
		const timelineRef = doc(db, 'timelines', selectedTimeline.id)
		await setDoc(
			timelineRef,
			{
				createdBy: {
					id: me.id,
					firstname: me.firstname,
					lastname: me.lastname,
					email: me.email,
					ref: doc(db, 'users', me.id)
				},
				image,
				updatedAt,
				name,
				description
			},
			{ merge: true }
		)

		const payload: TimelineProps = {
			id: selectedTimeline.id,
			image,
			name,
			description,
			createdAt: selectedTimeline.createdAt,
			updatedAt,
			createdBy: me,
			invitees: selectedTimeline.invitees
		}

		dispatch({ type: TimelinesActions.update, payload })

		setIsSaved(true)
		setTimeout(() => {
			setIsSaved(false)
		}, 3000)
	}

	const deleteTimeline = async () => {
		if (id) {
			try {
				dispatch({ type: TimelinesActions.updateLoading, payload: true })

				const timelineRef = doc(db, 'timelines', id)
				await deleteDoc(timelineRef)

				dispatch({ type: TimelinesActions.remove, payload: id })
				setIsDeleteConfirmationOpen(false)
				navigate('/timelines')
			} catch (error: any) {
				console.error(error)
			}
		}
	}

	return (
		<Layout>
			<DeleteConfirmation
				isLoading={isLoading}
				isOpen={isDeleteConfirmationOpen}
				onDelete={deleteTimeline}
				setIsOpen={setIsDeleteConfirmationOpen}
			/>
			<Wrapper>
				<BackWrapper>
					<Back onClick={() => navigate('/timelines')}>
						<BackIcon />
					</Back>
				</BackWrapper>
				<TimelinesWrapper>
					<Header>
						<Back onClick={() => navigate('/timelines')}>
							<BackIcon />
						</Back>{' '}
						<Title>{isEdit ? 'Update' : 'Add'} Timeline</Title>
					</Header>
					<Body>
						{isFormLoading ? (
							<Loader type='fill' />
						) : (
							<>
								<form onSubmit={onSubmit}>
									<UploadWrapper>
										{imageUrl || (imageFiles && Boolean(imageFiles.length)) ? (
											<>
												{imageFiles && Boolean(imageFiles.length) ? (
													<ImagePreviewWrapper>
														<ImagePreview src={URL.createObjectURL(imageFiles[0])} />
													</ImagePreviewWrapper>
												) : (
													<ImagePreviewWrapper>
														{imageUrl && <ImagePreview src={imageUrl} />}
													</ImagePreviewWrapper>
												)}
											</>
										) : (
											<ImagePreviewWrapper onClick={insertPhotos}>
												<AddPhotoIcon>
													<ImagesIcon />
												</AddPhotoIcon>
												<AddPhotoText>Timeline Image</AddPhotoText>
											</ImagePreviewWrapper>
										)}
										<FileInput
											ref={fileInputRef}
											key={Date.now()}
											type='file'
											name='file'
											accept='image/gif image/png, image/jpeg, image/jpg'
											onChange={(e: ChangeEvent<HTMLInputElement>) => {
												const files = e.currentTarget.files

												if (files && Boolean(files.length)) {
													setImageFiles(Array.from(files))
												}
											}}
										/>
										<UploadAction>
											<Button type='button' variant='secondary' onClick={insertPhotos}>
												{Boolean(imageFiles.length) ? 'Update' : ' Upload'} image
											</Button>
											<Button
												disabled={!Boolean(imageFiles.length)}
												type='button'
												variant='secondary'
												onClick={() => setImageFiles([])}>
												Remove image
											</Button>
										</UploadAction>
										{isLoading && <ProgressBar width={uploadProgress} />}
									</UploadWrapper>
									<Input
										label='Name'
										value={name}
										onChange={(e) => setName(e.currentTarget.value)}
									/>
									<Textarea
										label='Description'
										value={description}
										onChange={(e) => setDescription(e.currentTarget.value)}
									/>
									<Footer>
										{isEdit && (
											<Button
												variant='secondaryDanger'
												type='button'
												onClick={() => setIsDeleteConfirmationOpen(true)}
												isBlocked>
												Delete timeline
											</Button>
										)}
										{!isSaved ? (
											<Button type='submit' isLoading={isLoading} isBlocked>
												{isEdit ? 'Update' : 'Add'} timeline
											</Button>
										) : (
											<Button icon={<CheckIcon />} emphasis isBlocked>
												{isEdit ? 'Timeline updated' : 'Timeline added'}
											</Button>
										)}
									</Footer>
								</form>
							</>
						)}
					</Body>
				</TimelinesWrapper>
			</Wrapper>
		</Layout>
	)
}

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral005};
	display: flex;
	min-height: 100%;
	width: 100%;
	flex: 6;
	padding: 24px 12px;
	@media screen and (min-width: 768px) {
		padding: 46px 46px 46px 0;
	}
`

const BackWrapper = styled.div`
	display: none;
	justify-content: flex-end;
	width: 46px;
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

const Back = styled.button`
	background: none;
	border-radius: 7px;
	cursor: pointer;
	height: 24px;
	width: 32px;

	&:hover {
		opacity: 0.85;
	}

	&:active {
		opacity: 0.5;
	}
`

const TimelinesWrapper = styled.div`
	max-width: 400px;
	width: 100%;
`

export const Header = styled.div`
	display: flex;
	margin-bottom: 12px;
	@media screen and (min-width: 768px) {
		margin-bottom: 40px;
		${Back} {
			display: none;
		}
	}
`

const Title = styled.div`
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 10px;
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	& > form > * {
		margin-bottom: 24px;
	}
`

const UploadWrapper = styled.div`
	position: relative;
	margin-bottom: 32px !important;
`

const FileInput = styled.input`
	display: none;
`

const UploadAction = styled.div`
	display: flex;
	margin-top: 12px;
	margin-bottom: 12px;
	& > * {
		margin-right: 12px;
		flex: 1;
		&:last-child {
			margin-right: 0;
		}
	}
`

const AddPhotoIcon = styled.span`
	transition: transform 250ms ease-in-out;
	transform-origin: bottom left;
`

const AddPhotoText = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 18px;
	font-weight: 600;
	margin-left: 12px;
`

const ImagePreviewWrapper = styled.div`
	align-items: center;
	border-radius: 12px;
	background-color: ${(props) => props.theme.colors.neutral004};
	color: ${(props) => props.theme.colors.neutral003};
	display: flex;
	height: 250px;
	justify-content: center;
	width: 100%;

	&:hover {
		${AddPhotoIcon} {
			transform: rotate(-10deg);
		}

		${AddPhotoText} {
			opacity: 0.75;
		}
	}
`

const ImagePreview = styled.img`
	height: 250px;
	object-fit: cover;
	width: 250px;
`

const Footer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 24px 0;
	width: 100%;

	& > * {
		margin-bottom: 12px;
	}
`

export default TimelineForm
