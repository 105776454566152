import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react'
import reducer from './reducer'
import { UsersActionProps, UsersStateProps } from './types'

export const initialState: UsersStateProps = {
	data: [],
	isLoading: false
}

export const UsersState = createContext<UsersStateProps>(initialState)
export const UsersDispatch = createContext<Dispatch<UsersActionProps>>(() => null)
export const useUsersState = () => useContext(UsersState)
export const useUsersDispatch = () => useContext(UsersDispatch)

export const UsersProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, () => initialState)

	return (
		<UsersState.Provider value={state}>
			<UsersDispatch.Provider value={dispatch}>{children}</UsersDispatch.Provider>
		</UsersState.Provider>
	)
}
