import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from 'App'
import 'font/style.css'
import { AuthProvider } from 'providers/auth'
import { MeProvider } from 'providers/me'
import { TimelinesProvider } from 'providers/timelines'
import 'reset.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AuthProvider>
				<MeProvider>
					<TimelinesProvider>
						<App />
					</TimelinesProvider>
				</MeProvider>
			</AuthProvider>
		</BrowserRouter>
	</React.StrictMode>
)
