import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react'
import reducer from './reducer'
import { MomentsActionProps, MomentsStateProps } from './types'

export const initialState: MomentsStateProps = {
	data: [],
	isLoading: true,
	hasMore: false
}

export const MomentsState = createContext<MomentsStateProps>(initialState)
export const MomentsDispatch = createContext<Dispatch<MomentsActionProps>>(() => null)
export const useMomentsState = () => useContext(MomentsState)
export const useMomentsDispatch = () => useContext(MomentsDispatch)

export const MomentsProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, () => initialState)

	return (
		<MomentsState.Provider value={state}>
			<MomentsDispatch.Provider value={dispatch}>{children}</MomentsDispatch.Provider>
		</MomentsState.Provider>
	)
}
