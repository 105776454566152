import Context, { ContextMenuItemType } from 'components/block/contextMenu'
import Dropdown from 'components/element/dropdown'
import { blink } from 'components/keyframes'
import { signOut } from 'firebase/auth'
import { auth } from 'firebaseConfig'
import { ReactComponent as EyeIcon } from 'icons/eye.svg'
import { ReactComponent as LogoIcon } from 'icons/logoIcon.svg'
import { ReactComponent as ManageTimelinesIcon } from 'icons/manageTimelines.svg'
import { ReactComponent as ShareIcon } from 'icons/share.svg'
import { map } from 'lodash'
import { useMeDispatch, useMeState } from 'providers/me'
import { MeActions } from 'providers/me/types'
import { useTimelinesState } from 'providers/timelines'
import { TimelineProps } from 'providers/timelines/types'
import { FC, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { setCurrentTimeline } from 'utils'

export type TopNavigationLayout = {
	onToggle: (props?: any) => void
}
const TopNavigation: FC<TopNavigationLayout> = ({ onToggle }) => {
	const navigate = useNavigate()

	const me = useMeState()
	const { data: timelines } = useTimelinesState()
	const meDispatch = useMeDispatch()

	const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const menu: Array<ContextMenuItemType> = [
		{
			label: 'Profile',
			onClick: () => {}
		},
		{
			label: 'Manage timelines',
			onClick: () => {
				navigate('/timelines')
			}
		},
		{
			label: 'Settings',
			onClick: () => {}
		},
		{
			label: 'Logout',
			onClick: () => logout(),
			isDanger: true
		}
	]

	const onSetCurrentTimeline = (timeline: TimelineProps) => {
		setCurrentTimeline({
			timeline,
			onUpdate: () => setIsLoading(true),
			updated: ({ timelineRef }) => {
				meDispatch({
					type: MeActions.fetch,
					payload: {
						...me,
						currentTimeline: {
							id: timeline.id,
							image: timeline.image,
							name: timeline.name,
							description: timeline.description,
							createdAt: timeline.createdAt,
							updatedAt: timeline.updatedAt,
							invitees: timeline.invitees,
							ref: timelineRef
						}
					}
				})
				setIsLoading(false)
			},
			userId: me.id
		})
	}
	const onMenuClose = () => setIsMenuOpen(false)

	const logout = async () => {
		try {
			await signOut(auth)
			navigate('/login')
		} catch (error: any) {
			console.error(error)
		}
	}

	return (
		<Wrapper>
			<Logo to='/'>
				<LogoIcon />
			</Logo>
			<TimelineSelector>
				<Dropdown
					label={me?.currentTimeline?.name ? me.currentTimeline?.name : 'Select Timeline'}
					isLoading={me.isLoading || isLoading}
					menu={[
						{
							title: 'Timelines',
							items: map(timelines, (timeline) => {
								return {
									label: timeline.name,
									onClick: () => {
										onSetCurrentTimeline(timeline)
										navigate('/timeline')
										window.scrollTo(0, 0)
									}
								}
							})
						},
						{
							title: 'Shared timelines',
							items: map(me.sharedTimelines, (timeline) => {
								return {
									label: timeline.name,
									onClick: () => {
										onSetCurrentTimeline(timeline)
										navigate('/timeline')
										window.scrollTo(0, 0)
									}
								}
							})
						}
					]}
					action={{
						onClick: () => navigate('/timelines'),
						label: 'Manage timelines',
						icon: <ManageTimelinesIcon />
					}}
				/>
			</TimelineSelector>
			<Options>
				<ShareIcon />
			</Options>
			<Options onClick={() => navigate(`/timeline/${me?.id}/${me?.currentTimeline?.id}`)}>
				<EyeIcon />
			</Options>
			{me?.isLoading ? (
				<User isBlinking>
					<UserIcon />
				</User>
			) : (
				<UserContext
					toggle={
						<User onClick={() => setIsMenuOpen((isOpen) => !isOpen)}>
							<UserIcon>
								{me.firstname.charAt(0)}
								{me.lastname.charAt(0)}
							</UserIcon>
						</User>
					}
					menu={menu}
					isVisible={isMenuOpen}
					onClose={onMenuClose}
					position={{
						top: 'calc(100% + 10px)',
						right: '10px'
					}}
				/>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral005};
	display: flex;
	position: fixed;
	right: 0;
	top: 0;
	width: 100%;
	z-index: ${(props) => props.theme.indexes.fixed};

	@media screen and (min-width: 768px) {
		width: calc(100% - 101px);
	}
`

const MenuToggle = styled.button`
	background-color: ${(props) => props.theme.colors.neutral005};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 42px;
	width: 42px;
	border-radius: 7px;
	margin-left: 12px;
	margin-right: auto;
	& > span {
		display: flex;
		display: block;
		width: 20px;
		height: 3px;
		margin: 3px 0;
		border-radius: 3px;
		background-color: ${(props) => props.theme.colors.neutral003};
	}

	&:hover {
		opacity: 0.75;
	}

	&:active {
		opacity: 0.5;
	}
	@media screen and (min-width: 768px) {
		display: none;
	}
`

const TimelineSelector = styled.span`
	display: none;
	margin-left: 12px;
	margin-right: auto;
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

const Options = styled.div`
	align-items: center;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 70px;
	height: 50px;
	&:hover {
		opacity: 0.75;
	}
	@media screen and (min-width: 768px) {
		height: 70px;
	}
`

const User = styled.div<{ isBlinking?: boolean }>`
	align-items: center;
	cursor: pointer;
	display: none;
	height: 70px;
	justify-content: center;
	width: 70px;

	${(props) =>
		props.isBlinking &&
		css`
			animation: ${blink} 1s infinite;
		`}

	&:hover {
		opacity: 0.75;
	}
	@media screen and (min-width: 768px) {
		display: flex;
	}
`

const UserContext = styled(Context)`
	display: none;
	@media screen and (min-width: 768px) {
		display: flex;
	}
`
const UserIcon = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral004};
	border-radius: 40px;
	display: flex;
	font-size: 14px;
	font-weight: 900;
	height: 40px;
	justify-content: center;
	text-transform: uppercase;
	width: 40px;
`

const Logo = styled(Link)`
	align-items: center;
	display: flex;
	height: 50px;
	justify-content: center;
	width: 100px;
	margin-right: auto;
	& > svg {
		width: 50px;
	}
	@media screen and (min-width: 768px) {
		height: 71px;
		padding: 12px;
		display: none;
	}
`

export default TopNavigation
