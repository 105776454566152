import { map } from 'lodash'
import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'
import { ContextMenuItemType } from '../contextMenu'
import Item from './item'

export type ListProps = {
	data: Array<any>
	rowFormat: (props: any) => ReactNode
	onItemClick?: (props: any) => void
	menu?: Array<ContextMenuItemType>
}

const List: FC<ListProps> = ({ data, menu, rowFormat, onItemClick }) => {
	return (
		<Wrapper>
			{map(data, (item, index) => (
				<Item
					menu={menu}
					key={index}
					data={item}
					format={rowFormat}
					onClick={onItemClick}
				/>
			))}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	border-radius: 12px;
	border: 1px solid ${(props) => props.theme.colors.neutral005};
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 20px;
`

export default List
