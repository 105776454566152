import { useMeState } from 'providers/me'
import { FC } from 'react'
import styled from 'styled-components/macro'

export type TimelinePostProps = {
	onClick: () => void
}

const Post: FC<TimelinePostProps> = ({ onClick }) => {
	const me = useMeState()

	return (
		<Wrapper>
			<User>
				<UserIcon>
					{me.firstname.charAt(0)}
					{me.lastname.charAt(0)}
				</UserIcon>
			</User>
			<Textarea onClick={onClick}>Post a moment</Textarea>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: flex-start;
	background-color: ${(props) => props.theme.colors.neutral001};
	display: flex;
	padding: 24px 12px;
	width: 100%;
	@media screen and (min-width: 768px) {
		align-items: center;
		padding: 36px;
		border-radius: 12px;
	}
`

const User = styled.div`
	margin-right: 12px;
	@media screen and (min-width: 768px) {
		margin-right: 20px;
	}
`
const Textarea = styled.button`
	background-color: ${(props) => props.theme.colors.neutral005};
	border-radius: 12px;
	color: ${(props) => props.theme.colors.neutral003};
	cursor: pointer;
	font-size: 16px;
	font-weight: 500;
	padding: 14px 12px;
	text-align: left;
	width: 100%;

	&:hover {
		opacity: 0.75;
	}
	@media screen and (min-width: 768px) {
		padding: 20px;
		font-size: 20px;
	}
`

const UserIcon = styled.div`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral004};
	border-radius: 35px;
	display: flex;
	font-size: 14px;
	font-weight: 600;
	height: 35px;
	justify-content: center;
	text-transform: uppercase;
	width: 35px;
	@media screen and (min-width: 768px) {
		border-radius: 40px;
		font-size: 24px;
		height: 60px;
		width: 60px;
	}
`

export default Post
