export type ThemeType = typeof light

const common = {
	font: {
		family: {
			default: '"Suit", sans-serif'
		}
	},
	indexes: {
		dropdown: '1000',
		sticky: '1020',
		fixed: '1030',
		backdrop: '1040',
		modal: '1050',
		popover: '1060',
		tooltip: '1070'
	}
}

export const light = {
	type: 'LIGHT',
	colors: {
		danger: '#c93232',
		neutral000: '#031723',
		neutral001: '#ffffff',
		neutral002: '#2D3133',
		neutral003: '#8096A3',
		neutral004: '#DBE2E5',
		neutral005: '#EFF3F5',
		neutral006: '#FAFDFF',
		primary: '#F05E52',
		success: '#22C489',
		warning: '#FF9838'
	},
	...common
}

export const dark = {
	type: 'DARK',
	colors: {
		danger: '#861e1e',
		neutral000: '#e6ecf1',
		neutral001: '#000d12',
		neutral002: '#e7f5fb',
		neutral003: '#a6bfc9',
		neutral004: '#093447',
		neutral005: '#06222f',
		neutral006: '#00080b',
		primary: '#F05E52',
		success: '#22C489',
		warning: '#FF9838'
	},
	...common
}

const getTheme = (theme: string) => (theme === 'dark' ? dark : light)

const theme = getTheme('light')
export default theme
