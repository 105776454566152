import { blink } from 'components/keyframes'
import { ReactComponent as DownArrowIcon } from 'icons/chevronDown.svg'
import { map } from 'lodash'
import { darken, transparentize } from 'polished'
import { FC, Fragment, ReactElement, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import styled, { css } from 'styled-components/macro'

export type MenuActionProps = {
	onClick: () => void
	label: string
}

export type DropdownMenuProps = {
	title?: string
	items: Array<MenuActionProps>
}

export type DropdownProps = {
	action: MenuActionProps & { icon: ReactElement }
	isLoading?: boolean
	label: string
	menu: Array<DropdownMenuProps>
}

const Dropdown: FC<DropdownProps> = ({ action, isLoading = false, label, menu }) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const onClose = () => setIsOpen(false)
	const ref = useDetectClickOutside({ onTriggered: onClose })

	const onToggle = () => setIsOpen((isOpen) => !isOpen)

	return (
		<Wrapper ref={ref}>
			<ToggleWrapper
				isLoading={isLoading}
				onClick={() => {
					!isLoading && onToggle()
				}}>
				<Label>{!isLoading ? label : ' '}</Label>
				<Icon>
					<DownArrowIcon />
				</Icon>
			</ToggleWrapper>
			{isOpen && (
				<Menu>
					{map(menu, ({ title, items }, index) => {
						if (items.length) {
							return (
								<Fragment key={index}>
									{title && <Title>{title}</Title>}
									{map(items, ({ onClick, label }, index) => (
										<MenuItem
											key={index}
											onClick={() => {
												onClick()
												setIsOpen(false)
											}}>
											{label}
										</MenuItem>
									))}
								</Fragment>
							)
						}
					})}
					{action && (
						<Action
							onClick={() => {
								action.onClick()
								setIsOpen(false)
							}}>
							<ActionIcon>{action.icon && action.icon}</ActionIcon>
							<ActionLabel>{action.label}</ActionLabel>
						</Action>
					)}
				</Menu>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	min-width: 200px;
	width: 100%;
`

const ToggleWrapper = styled.div<{ isLoading: boolean }>`
	display: flex;
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral004};
	padding: 10px 12px;
	border-radius: 7px;
	cursor: pointer;
	&:hover {
		background-color: ${(props) => darken(0.05, props.theme.colors.neutral005)};
	}

	${(props) =>
		props.isLoading &&
		css`
			animation: ${blink} 1s linear infinite;
		`}
`

const Label = styled.div`
	font-weight: 600;
	font-size: 20px;
	margin-right: auto;
`

const Icon = styled.div`
	margin-left: 10px;
`

const Menu = styled.div`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 7px;
	box-shadow: 0 3px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	left: 0;
	min-width: 100%;
	position: absolute;
	top: calc(100% + 10px);
	width: auto;
	z-index: ${(props) => props.theme.indexes.popover};
`

const Title = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral004};
	color: ${(props) => darken(0.02, props.theme.colors.neutral003)};
	font-size: 12px;
	font-weight: 600;
	padding: 5px 12px;
`

const MenuItem = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral004};
	cursor: pointer;
	font-size: 16px;
	font-weight: 600;
	padding: 12px;
	&:hover {
		background-color: ${(props) => darken(0.02, props.theme.colors.neutral005)};
	}

	&:active {
		background-color: ${(props) => darken(0.075, props.theme.colors.neutral005)};
	}
`

const Action = styled.button`
	align-items: center;
	background: ${(props) => props.theme.colors.neutral006};
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	border: none;
	color: ${(props) => props.theme.colors.neutral003};
	cursor: pointer;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	justify-content: center;
	padding: 16px;
	white-space: nowrap;
	width: 100%;
	&:hover {
		background-color: ${(props) => darken(0.05, props.theme.colors.neutral005)};
	}

	&:active {
		background-color: ${(props) => darken(0.075, props.theme.colors.neutral005)};
	}
`

const ActionIcon = styled.span`
	margin-right: 10px;
`

const ActionLabel = styled.span`
	white-space: nowrap;
`

export default Dropdown
