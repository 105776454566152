import { FC, ReactNode } from 'react'
import styled from 'styled-components/macro'

export type SiteLayoutProps = {
	children: ReactNode
}

const Layout: FC<SiteLayoutProps> = ({ children }) => {
	return (
		<Main>
			<Wrapper>{children}</Wrapper>
		</Main>
	)
}

const Main = styled.main`
	background-color: ${(props) => props.theme.colors.neutral005};
	display: flex;
	min-height: 100vh;
`
const Wrapper = styled.div`
	flex: 1;
`

export default Layout
