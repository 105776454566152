import { auth, db } from 'firebaseConfig'
import { doc, getDoc } from 'firebase/firestore'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { initialMeState, useMeDispatch } from '.'
import { MeActions } from './types'
import { UserProps } from 'providers/users/types'

export const useFetchMe = (): {
	data: UserProps
	setData: Dispatch<SetStateAction<UserProps>>
} => {
	const [data, setData] = useState<UserProps>(initialMeState)
	const dispatch = useMeDispatch()

	/* eslint-disable */
	useEffect(() => {
		const fetch = async () => {
			if (!auth.currentUser) {
				return
			}

			try {
				dispatch({ type: MeActions.updateLoading, payload: true })

				const docRef = doc(db, 'users', `${auth?.currentUser?.uid}`)
				const docSnap = await getDoc(docRef)

				if (docSnap.exists()) {
					//format data
					console.log(docSnap.data())
					const userSnapData = docSnap.data() as UserProps
					const currentUser = auth.currentUser
					const meData: UserProps = {
						...userSnapData,
						currentTimeline: userSnapData?.currentTimeline,
						displayName: `${currentUser?.displayName}`,
						email: `${currentUser?.email}`,
						id: currentUser?.uid,
						isLoading: false
					}

					setData(meData)
					dispatch({
						type: MeActions.fetch,
						payload: meData
					})
					return
				}

				dispatch({ type: MeActions.updateLoading, payload: false })
			} catch (err) {
				dispatch({ type: MeActions.updateLoading, payload: false })
				console.error(err)
			}
			dispatch({ type: MeActions.updateLoading, payload: false })
		}

		fetch()
	}, [auth?.currentUser?.uid])
	/* eslint-enable */

	return { data, setData }
}
