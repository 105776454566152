import { FC, FormEvent, ReactNode, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { ReactComponent as ChevronDownIcon } from 'icons/chevronDown.svg'

export type SelectProps = {
	children: ReactNode
	error?: string
	isBlocked?: boolean
	label: string
	placeholder?: string
	title?: string
	value: string | number | undefined
	width?: number
	onBlur?: (e: FormEvent<HTMLSelectElement>) => void
	onChange: (e: FormEvent<HTMLSelectElement>) => void
	onFocus?: (e: FormEvent<HTMLSelectElement>) => void
}

const Select: FC<SelectProps> = ({
	isBlocked = false,
	children,
	error,
	label,
	placeholder,
	title = label,
	value = undefined,
	width,
	onBlur,
	onChange,
	onFocus,
	...rest
}: SelectProps) => {
	const [isFocus, setIsFocus] = useState<boolean>(false)
	const active = Boolean(`${value}`.trim()) || isFocus || Boolean(placeholder)

	return (
		<Wrapper
			{...rest}
			hasError={Boolean(error)}
			hasLabel={Boolean(label)}
			isBlocked={isBlocked}
			isFocus={isFocus}
			width={width}>
			<SelectIconWrapper>
				<SelectWrapper
					id={label.replace(' ', '')}
					aria-label={label}
					name={label}
					placeholder={placeholder}
					title={title}
					value={value}
					onBlur={(e: any) => {
						setIsFocus(false)
						onBlur && onBlur(e)
					}}
					onChange={(e: any) => {
						onChange(e)
					}}
					onFocus={(e: any) => {
						setIsFocus(true)
						onFocus && onFocus(e)
					}}>
					{children}
				</SelectWrapper>
				<DropIcon />
			</SelectIconWrapper>
			{label && (
				<Label htmlFor={`${label.replace(' ', '')}`} isActive={active}>
					{label}
				</Label>
			)}
			<Error>{error}</Error>
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	hasError: boolean
	hasLabel: boolean
	isBlocked: boolean
	isFocus: boolean
	width?: number
}>`
	background: inherit;
	border-radius: 12px;
	border: 2px solid
		${(props) =>
			props.isFocus
				? props.hasError
					? props.theme.colors.danger
					: props.theme.colors.neutral000
				: props.hasError
				? props.theme.colors.danger
				: props.theme.colors.neutral003};
	display: flex;
	flex-direction: column-reverse;
	height: 50px;
	padding: 11px 15px;
	position: relative;
	text-align: left;
	transition: all 250ms ease-in-out;
	width: ${(props) =>
		props.isBlocked ? '100%' : `${props.width ? `${props.width}px` : 'auto'}`};
`

const SelectIconWrapper = styled.div`
	align-items: center;
	display: flex;
	position: relative;
`

const Label = styled.label<{ isActive: boolean }>`
	background: ${(props) => props.theme.colors.neutral001};
	color: ${(props) =>
		props.isActive ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	font-size: 18px;
	left: 10px;
	padding: 0 5px;
	pointer-events: none;
	position: absolute;
	top: 14px;
	transform-origin: left;
	transition: all 250ms ease-in-out;
	z-index: 2;
	${(props) =>
		props.isActive &&
		css`
			top: -10px;
			transform: scale(0.8);
		`}
`

const SelectWrapper = styled.select`
	appearance: none;
	background: none;
	color: ${(props) => props.theme.colors.neutral000};
	font-family: ${(props) => props.theme.font.family.default};
	font-size: 18px;
	outline: none;
	z-index: 1;
	width: 100%;
`

const DropIcon = styled(ChevronDownIcon)`
	position: absolute;
	right: 0;
`

const Error = styled.div`
	top: 50px;
	color: ${(props) => props.theme.colors.danger};
	font-size: 12px;
	font-weight: 600;
	left: 0;
	letter-spacing: 0.02em;
	position: absolute;
`

export default Select
