import Layout from 'components/layouts/main'
import Select from 'components/element/select'
import useFetchFonts from 'hooks/useFetchFonts'
import { map } from 'lodash'
import { useState } from 'react'
import styled from 'styled-components/macro'
import ThemeSelector from './ThemeSelector'
import { ThemeProps } from './ThemeSelector/theme'
import data from './themes.json'

const Appearance = () => {
	const [themes] = useState<Array<ThemeProps>>(data)
	const fonts = useFetchFonts()

	const [theme, setTheme] = useState<string>('')
	const [primaryFont, setPrimaryFont] = useState<string>('')
	const [secondaryFont, setSecondaryFont] = useState<string>('')

	return (
		<Layout>
			<Wrapper>
				<SettingsWrapper>
					<Header>
						<Title>Appearance</Title>
					</Header>
					<Body>
						<ThemeSelector themes={themes} value={theme} onChange={setTheme} />

						<AppearanceSelect
							label='Primary Font'
							value={primaryFont}
							onChange={(e) => setPrimaryFont(e.currentTarget.value)}
							width={320}>
							{map(fonts, (font: any, index) => (
								<option key={index} value={font.family}>
									{font.family}
								</option>
							))}
						</AppearanceSelect>

						<AppearanceSelect
							label='Secondary Font'
							value={secondaryFont}
							onChange={(e) => setSecondaryFont(e.currentTarget.value)}
							width={320}>
							{map(fonts, (font: any, index) => (
								<option key={index} value={font.family}>
									{font.family}
								</option>
							))}
						</AppearanceSelect>
					</Body>
				</SettingsWrapper>
				<Preview></Preview>
			</Wrapper>
		</Layout>
	)
}

const Wrapper = styled.div`
	display: flex;
	min-height: 100%;
	width: 100%;
`

const Header = styled.div`
	display: flex;
	margin-bottom: 24px;
	@media screen and (min-width: 992px) {
		margin-bottom: 40px;
	}
`

const Title = styled.div`
	font-size: 24px;
	font-weight: 600;
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
`

const AppearanceSelect = styled(Select)`
	margin-bottom: 40px;
`

const SettingsWrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral001};
	display: flex;
	flex-direction: column;
	min-height: 100%;
	padding: 24px 12px;
	width: 100%;
	flex: 6;
	@media screen and (min-width: 992px) {
		padding: 46px;
	}
`

const Preview = styled.div`
	display: none;
	flex: 4;
	@media screen and (min-width: 992px) {
		display: flex;
	}
`

export default Appearance
