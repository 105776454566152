import Modal from 'components/block/modal'
import { addDoc, collection, doc, Timestamp } from 'firebase/firestore'
import { ref, uploadBytesResumable } from 'firebase/storage'
import { db, storage } from 'firebaseConfig'
import { map, reduce, set } from 'lodash'
import { DateTime } from 'luxon'
import { useMeState } from 'providers/me'
import { useMomentsDispatch } from 'providers/moments'
import { MomentProps, MomentsActions } from 'providers/moments/types'
import { FC, FormEvent, useEffect, useRef, useState } from 'react'
import MomentForm from './momentForm'
import PhotoManager from './photoManager'

export type TimelineFormProps = {
	isOpen: boolean
	moment: MomentProps | null
	onClose: () => void
	setIsOpen: (isOpen: boolean) => void
}

const Form: FC<TimelineFormProps> = ({ isOpen, moment, onClose, setIsOpen }) => {
	const me = useMeState()
	const dispatch = useMomentsDispatch()

	const fileInputRef = useRef<HTMLInputElement>(null)
	const [description, setDescription] = useState<string>('')
	const [title, setTitle] = useState<string>('')
	const [date, setDate] = useState<Date>(new Date())
	const [isSaved, setIsSaved] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [imageFiles, setImageFiles] = useState<Array<File>>([])
	const [imageUrls, setImageUrls] = useState<Array<string>>([])
	const [uploadProgress, setUploadProgress] = useState<any>()
	const [isPhotoManangerOpen, setIsPhotoManagerOpen] = useState<boolean>(false)

	// todo: fix image upload progress
	const uploadImage = async (image: File, index: number) => {
		if (!image) {
			return
		}

		return new Promise((resolve, reject) => {
			const imageNameArray = image?.name.split('.')
			const extension = imageNameArray[imageNameArray.length - 1]
			const date = DateTime.now()
			const filename = `${date.toISODate()}-${Date.now()}.${extension}`

			const momentImagesRef = ref(storage, `moments/${me.id}/${filename}`)
			const uploadTask = uploadBytesResumable(momentImagesRef, image)

			uploadTask.on(
				'state_changed',
				(snapshot) => {
					const progress = Math.round(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					)
					setUploadProgress((uploadProgress: any) => set(uploadProgress, index, progress))
				},
				(error) => {
					alert(error)
					reject(error)
				},
				() => {
					resolve(filename)
				}
			)
		})
	}

	const addMoment = async (e: FormEvent) => {
		e.preventDefault()

		if (!me?.currentTimeline?.id) {
			return
		}
		setIsLoading(true)

		//promise all here
		const filenames = await Promise.all(
			map(imageFiles, (image, index) => {
				return uploadImage(image, index)
			})
		)

		const createdAt = new Date()
		const updatedAt = createdAt

		const timelineRef = doc(db, 'timelines', me.currentTimeline.id)
		const images = filenames.length ? (filenames as Array<string>) : []

		const momentDate = DateTime.fromJSDate(date)
		const moment = await addDoc(collection(db, 'moments'), {
			createdAt,
			date: Timestamp.fromDate(momentDate.toJSDate()),
			description,
			images,
			timeline: timelineRef,
			title,
			updatedAt
		})

		const payload: MomentProps = {
			id: moment.id,
			createdAt,
			description,
			date: momentDate.toJSDate(),
			images,
			timeline: timelineRef,
			title: title,
			updatedAt
		}

		setIsLoading(false)
		dispatch({ type: MomentsActions.add, payload })
		setIsSaved(true)

		setTimeout(() => {
			clearForm()
			setIsSaved(false)
			setIsOpen(false)
		}, 1000)
	}

	const clearForm = () => {
		setDescription('')
		setTitle('')
		setDate(new Date())
		setUploadProgress([])
		setImageFiles([])
	}

	const closeModal = () => {
		onClose()
		setIsOpen(false)
	}

	const insertPhotos = () => fileInputRef.current?.click()

	const clearPhotos = () => setImageFiles([])

	const openPhotoManager = () => setIsPhotoManagerOpen(true)

	const progressBarWith =
		uploadProgress && Boolean(uploadProgress.length)
			? reduce(
					map(Object.keys(uploadProgress), (uP) => {
						return uploadProgress[uP]
					}),
					(sum, n) => sum + n
			  ) / imageFiles.length
			: 0

	useEffect(() => {
		if (moment) {
			setDescription(moment.description)
			setTitle(moment.title)
			setDate(moment.date)
			setImageFiles([])
			setImageUrls(moment.images)
		}
	}, [moment])

	return (
		<form onSubmit={addMoment}>
			<Modal isOpen={isOpen} setIsOpen={setIsOpen}>
				<PhotoManager
					fileInputRef={fileInputRef}
					imageFiles={imageFiles}
					imageUrls={imageUrls}
					isOpen={isPhotoManangerOpen}
					moment={moment}
					clearPhotos={clearPhotos}
					setImageFiles={setImageFiles}
					setImageUrls={setImageUrls}
					setIsFormOpen={setIsOpen}
					setIsOpen={setIsPhotoManagerOpen}
				/>
				<MomentForm
					isVisible={!isPhotoManangerOpen}
					date={date}
					description={description}
					fileInputRef={fileInputRef}
					imageFiles={imageFiles}
					isLoading={isLoading}
					isSaved={isSaved}
					progressBarWith={progressBarWith}
					title={title}
					closeModal={closeModal}
					insertPhotos={insertPhotos}
					openPhotoManager={openPhotoManager}
					setDate={setDate}
					setDescription={setDescription}
					setImageFiles={setImageFiles}
					setTitle={setTitle}
				/>
			</Modal>
		</form>
	)
}

export default Form
