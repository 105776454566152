import Loader from 'components/block/loader'
import Button from 'components/element/button'
import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from 'firebaseConfig'
import { map } from 'lodash'
import { DateTime, Info } from 'luxon'
import { transparentize } from 'polished'
import { FC, useEffect, useMemo, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import { limitString } from 'utils'
import Image from './image'

export type MomentProps = {
	id: string
	index: number
	date: Date
	description: string
	onClick: (photos: Array<string>) => void
	photos: Array<string>
	title: string
	userId: string
}

const Moment: FC<MomentProps> = ({
	index,
	date,
	description,
	photos,
	title,
	userId,
	onClick
}) => {
	const DESCRIPTION_LIMIT = 160
	const TITLE_LIMIT = 200

	const dt = DateTime.fromJSDate(date)

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isDescriptionLimited, setIsDescriptionLimited] = useState<boolean>(true)
	const [isTitleLimited, setIsTitleLimited] = useState<boolean>(true)

	const [photoUrls, setPhotoUrls] = useState<Array<string>>([])
	const [rowOne, setRowOne] = useState<Array<string>>([])
	const [rowTwo, setRowTwo] = useState<Array<string>>([])

	const isSingle = useMemo(() => photoUrls.length === 1, [photoUrls])

	useEffect(() => {
		const fn = async () => {
			setIsLoading(true)
			if (Boolean(photos?.length)) {
				const urls = await Promise.all(
					map(photos, (photo) => {
						const photoRef = ref(storage, `moments/${userId}/${photo}`)

						return getDownloadURL(photoRef)
					})
				)

				setIsLoading(false)

				switch (urls.length) {
					case 1:
						setRowOne(urls)
						break
					case 2:
						setRowOne([])
						setRowTwo(index % 2 === 0 ? [...urls, 'offset'] : ['offset', ...urls])
						break
					case 3:
						setRowOne([...urls.slice(0, 2)])
						setRowTwo(
							index % 2 === 0
								? [...urls.slice(2), 'offset']
								: ['offset', ...urls.slice(2)]
						)
						break

					case 4:
						setRowOne([...urls.slice(0, 2)])
						setRowTwo(
							index % 2 === 0
								? [...urls.slice(2), 'offset']
								: ['offset', ...urls.slice(2)]
						)
						break
					default:
						setRowOne([...urls.slice(0, 3)])
						setRowTwo(
							index % 2 === 0
								? [...urls.slice(4, 6), 'offset']
								: ['offset', ...urls.slice(4, 6)]
						)
				}
				setPhotoUrls(urls)
			} else {
				setIsLoading(false)

				setRowOne([])
				setRowTwo([])
				setPhotoUrls([])
			}
		}

		fn()
	}, [photos, userId, index])

	if (isLoading) {
		return <Loader />
	}
	return (
		<Wrapper>
			<ImagesWrapper>
				{isSingle ? (
					<Images index={index}>
						<Image index={index} src={photoUrls[0]} isSingle />
					</Images>
				) : (
					<>
						<Images index={index}>
							{Boolean(rowOne?.length) &&
								map(rowOne, (photo, key) => {
									return <Image index={Number(index)} key={key} src={photo} />
								})}
						</Images>
						<Images index={index}>
							{Boolean(rowTwo?.length) &&
								map(rowTwo, (photo, key) => {
									if (photo === 'offset') {
										return <Image key={key} index={Number(index)} isFiller />
									}
									return <Image index={Number(index)} key={key} src={photo} />
								})}
						</Images>
					</>
				)}

				<Details index={index}>
					<DateText index={index}>
						<Month>{Info.months('short')[dt.month - 1]}</Month>
						<Day>{dt.toFormat('dd')}</Day>
					</DateText>
					<Title className='customFont'>
						{isTitleLimited ? limitString(title, TITLE_LIMIT) : title}
						<ReadMore onClick={() => setIsTitleLimited((isLimited) => !isLimited)}>
							{title.length > TITLE_LIMIT && (isTitleLimited ? 'see more' : 'see less')}
						</ReadMore>
					</Title>
					<Description>
						<Description>
							{isDescriptionLimited
								? limitString(description, DESCRIPTION_LIMIT)
								: description}
							<ReadMore
								onClick={() => setIsDescriptionLimited((isLimited) => !isLimited)}>
								{description.length > DESCRIPTION_LIMIT &&
									(isDescriptionLimited ? 'see more' : 'see less')}
							</ReadMore>
						</Description>
					</Description>
					<Button
						className='customButton'
						onClick={() => {
							onClick(photoUrls)
						}}>
						View moment
					</Button>
				</Details>
			</ImagesWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: center;
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	overflow: hidden;
	@media screen and (min-width: 768px) {
		align-items: flex-start;
	}
`

const ImagesWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: center;
	margin: 0 auto;
	max-width: 1200px;
	position: relative;
	width: 100%;
`

const Images = styled.div<{ index: number }>`
	width: auto;
	margin-top: 32px;
	display: flex;
	min-width: 100%;
	justify-content: center;
	${(props) => {
		if (props.index % 2 === 0) {
			return css`
				justify-content: flex-start;
			`
		} else {
			return css`
				justify-content: flex-end;
			`
		}
	}}
`

const Title = styled.span`
	font-weight: 600;
	font-size: 18px;
	letter-spacing: 0.015em;
	line-height: 28px;
	margin-top: 12px;
	@media screen and (min-width: 768px) {
		font-size: 32px;
		line-height: 32px;
		margin-top: 32px;
	}
`

const Description = styled.span`
	color: ${(props) => props.theme.colors.neutral000};
	font-weight: 400;
	font-size: 14px;
	letter-spacing: 0.015em;
	line-height: 24px;
	margin-top: 12px;
	margin-bottom: 12px;
	@media screen and (min-width: 768px) {
		font-size: 18px;
		letter-spacing: 0.015em;
		line-height: 32px;
		margin-top: 12px;
		margin-bottom: 24px;
	}
`

const Time = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	font-weight: 400;
	font-size: 14px;
	margin-top: 12px;
	margin-bottom: 12px;
	@media screen and (min-width: 768px) {
		font-size: 16px;
		line-height: 20px;
		margin-top: 10px;
		margin-bottom: 32px;
	}
`

const Details = styled.div<{ index: number }>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border: 7px solid ${(props) => props.theme.colors.neutral001};
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	width: 90vw;
	padding: 24px;
	height: fit-content;
	z-index: 1;
	margin-bottom: 24px;
	align-items: flex-start;

	@media screen and (min-width: 768px) {
		position: absolute;
		width: 500px;
		${(props) => {
			if (props.index % 2 === 0) {
				return css`
					right: 32px;
					bottom: 24px;
					transform: rotate(1deg);
				`
			} else {
				return css`
					left: 32px;
					bottom: 24px;
					transform: rotate(-1deg);
				`
			}
		}}
	}
`

const DateText = styled.div<{ index: number }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #ffdddd;
	border: 3px solid #ffffff;
	box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.25);
	border-radius: 7px;
	padding: 12px 16px;

	@media screen and (min-width: 768px) {
		border: 7px solid #ffffff;
		width: 85px;
		height: 95px;
		padding: 0;
		${(props) => {
			if (props.index % 2 === 0) {
				return css`
					transform: rotate(5deg);
				`
			} else {
				return css`
					transform: rotate(-5deg);
				`
			}
		}}
	}
`

const Month = styled.span`
	color: #7c2e2e;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
`

const Day = styled.span`
	color: #cd9393;
	font-size: 28px;
	font-weight: 600;
	line-height: 32px;
`

const ReadMore = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	cursor: pointer;
	font-weight: 400;
	&:hover {
		color: ${(props) => props.theme.colors.primary};
	}
`

export default Moment
