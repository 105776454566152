import { ContextMenuItemType } from 'components/block/contextMenu'
import DeleteConfirmation from 'components/block/deleteConfirmation'
import List from 'components/block/list'
import { Label } from 'components/block/list/item'
import Loader from 'components/block/loader'
import Button from 'components/element/button'
import Layout from 'components/layouts/main'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { isNil } from 'lodash'
import { useMeState } from 'providers/me'
import { useTimelinesDispatch, useTimelinesState } from 'providers/timelines'
import { TimelineProps, TimelinesActions } from 'providers/timelines/types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'

const Timelines = () => {
	const navigate = useNavigate()

	const { isLoading: meIsLoading, sharedTimelines } = useMeState()
	const { data, isLoading } = useTimelinesState()
	const timelineDispatch = useTimelinesDispatch()

	const [timelineData, setTimelineData] = useState<TimelineProps>()
	const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false)

	const onDeleteTimeline = async () => {
		if (timelineData) {
			try {
				timelineDispatch({ type: TimelinesActions.updateLoading, payload: true })

				const timelineRef = doc(db, 'timelines', timelineData?.id)
				await deleteDoc(timelineRef)

				timelineDispatch({ type: TimelinesActions.remove, payload: timelineData?.id })
				setIsDeleteConfirmationOpen(false)
			} catch (error: any) {
				console.error(error)
			}
		}
	}

	const timelineMenu: Array<ContextMenuItemType> = [
		{
			label: 'Update',
			onClick: (data: TimelineProps) => {
				navigate(`${data.id}`)
			}
		},
		{
			label: 'Delete',
			isDanger: true,
			onClick: (data: TimelineProps) => {
				setIsDeleteConfirmationOpen(true)
				setTimelineData(data)
			}
		}
	]

	const sharedTimelineMenu: Array<ContextMenuItemType> = [
		{
			label: 'Update',
			onClick: (data: TimelineProps) => {
				navigate(`${data.id}`)
			}
		}
	]

	return (
		<Layout>
			<DeleteConfirmation
				isOpen={isDeleteConfirmationOpen}
				setIsOpen={setIsDeleteConfirmationOpen}
				onDelete={onDeleteTimeline}
				isLoading={isLoading}
			/>
			<Wrapper>
				<TimelinesWrapper>
					<Header>
						<Title>Timelines</Title>
						<Text>Manage your timelines</Text>
					</Header>
					<Body>
						{isLoading || meIsLoading ? (
							<Loader type='fill' />
						) : (
							<List
								data={data}
								rowFormat={(props: TimelineProps) => {
									return <Label>{props.name}</Label>
								}}
								onItemClick={(props: TimelineProps) => {
									navigate(`/timelines/${props.id}`)
								}}
								menu={timelineMenu}
							/>
						)}
						{isLoading || meIsLoading ? (
							<Loader type='fill' />
						) : (
							<>
								{!isNil(sharedTimelines) && (
									<>
										<TitleSmall>Shared timelines</TitleSmall>
										<List
											data={sharedTimelines}
											rowFormat={(props: TimelineProps) => {
												return <Label>{props.name}</Label>
											}}
											onItemClick={(props: TimelineProps) => {
												navigate(`/timelines/${props.id}`)
											}}
											menu={sharedTimelineMenu}
										/>
									</>
								)}
							</>
						)}
						<Button onClick={() => navigate('/timelines/add')}>Add timeline</Button>
					</Body>
				</TimelinesWrapper>
			</Wrapper>
		</Layout>
	)
}

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral005};
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
	flex: 6;
	padding: 24px 12px;
	@media screen and (min-width: 768px) {
		padding: 46px;
	}
`

const TimelinesWrapper = styled.div`
	max-width: 400px;
`

const Header = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`

const Title = styled.div`
	font-size: 24px;
	font-weight: 600;
	margin-bottom: 10px;
`
const TitleSmall = styled.div`
	font-size: 18px;
	font-weight: 600;
	margin-bottom: 10px;
`

const Text = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 18px;
	font-weight: 400;
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
`

export default Timelines
