import Layout from 'components/layouts/main'
import styled from 'styled-components/macro'

import DeleteTimeline from './deleteTimeline'
import Invites from './invites'

const Settings = () => {
	return (
		<Layout>
			<Wrapper>
				<SettingsWrapper>
					<Header>
						<Title>Settings</Title>
					</Header>
					<Body>
						<Invites />
						<DeleteTimeline />
					</Body>
				</SettingsWrapper>
			</Wrapper>
		</Layout>
	)
}

const Wrapper = styled.div`
	background-color: ${(props) => props.theme.colors.neutral001};
	display: flex;
	flex-direction: column;
	flex: 6;
	min-height: 100%;
	padding: 24px 12px;
	width: 100%;
	@media screen and (min-width: 768px) {
		padding: 46px;
	}
`

const SettingsWrapper = styled.div`
	max-width: 400px;
`

const Header = styled.div`
	display: flex;
	margin-bottom: 40px;
`

const Title = styled.div`
	font-size: 24px;
	font-weight: 600;
`

const Body = styled.div`
	display: flex;
	flex-direction: column;
`

export default Settings
