import { transparentize } from 'polished'
import { FC } from 'react'
import styled from 'styled-components/macro'

export type ModalProps = {
	children: React.ReactNode
	isOpen: boolean
	setIsOpen: (props: boolean) => void
}

const Modal: FC<ModalProps> = ({ children, isOpen, setIsOpen }) => {
	if (!isOpen) {
		return <></>
	}
	return (
		<>
			<Wrapper>
				<Backdrop onClick={() => setIsOpen(false)} />
				{children}
			</Wrapper>
			<div>style</div>
		</>
	)
}

const Wrapper = styled.div`
	align-items: flex-start;
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: ${(props) => props.theme.indexes.backdrop};
	@media screen and (min-width: 768px) {
		align-items: center;
	}
`

const Backdrop = styled.div`
	background-color: ${(props) => transparentize(0.5, props.theme.colors.neutral003)};
	height: 100vh;
	left: 0;
	position: absolute;
	top: 0;
	width: 100vw;
	backdrop-filter: blur(5px);
`

export const Card = styled.div`
	align-items: center;
	align-self: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	display: flex;
	flex-direction: column;
	justify-self: center;
	max-height: 95%;
	max-width: 400px;
	overflow: auto;
	position: absolute;
	width: 100%;
	z-index: ${(props) => props.theme.indexes.modal};
	@media screen and (min-width: 768px) {
		border-radius: 12px;
		bottom: unset;
	}
`

export const Header = styled.div`
	display: flex;
	padding: 24px;
	align-items: center;
	width: 100%;
`

export const Close = styled.button`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral004};
	border-radius: 35px;
	cursor: pointer;
	display: flex;
	flex: none;
	height: 35px;
	justify-content: center;
	margin: 0;
	padding: 0;
	width: 35px;

	&:hover {
		opacity: 0.75;
	}
	&:active {
		opacity: 0.5;
	}
`

export const Title = styled.div`
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	width: 100%;
`

export const Text = styled.div`
	color: ${(props) => props.theme.colors.neutral000};
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 10px;
`

export const Body = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	padding: 0 24px;

	& > form > * {
		margin-bottom: 24px;
	}
`

export const Footer = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	width: 100%;
	padding: 24px;

	& > * {
		margin-bottom: 12px;
	}
`

export default Modal
