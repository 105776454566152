import { transparentize } from 'polished'
import { FC, useMemo } from 'react'
import styled from 'styled-components/macro'

export type TimelineImageProps = {
	isFiller?: boolean
	isSingle?: boolean
	index: number
	src?: string
}

const Image: FC<TimelineImageProps> = ({ isFiller, isSingle, index, src, ...rest }) => {
	const maxTilt = 2
	const minTilt = -2

	const tilt = useMemo(
		() =>
			isSingle
				? index % 2 === 0
					? -1
					: 1
				: Math.random() * (maxTilt - minTilt) + minTilt,
		[]
	)
	const scale = useMemo(() => (isSingle ? 1.3 : 1), [])

	if (isFiller) {
		return <Filler />
	}
	return <Wrapper tilt={tilt} scale={scale} alt='' src={src} index={index} {...rest} />
}

const Filler = styled.div`
	height: 240px;
	width: 240px;
	display: none;
	@media screen and (min-width: 768px) {
		display: flex;
		height: 400px;
		width: 400px;
	}
`

const Wrapper = styled.img<{ index: number; tilt: number; scale: number }>`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border: 7px solid ${(props) => props.theme.colors.neutral001};
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	cursor: pointer;
	object-fit: cover;
	object-position: top center;
	height: 300px;
	width: auto;
	transform: rotate(${(props) => props.tilt}deg);
	margin: 7px;
	@media screen and (min-width: 768px) {
		margin: 12px;
		transform: rotate(${(props) => props.tilt}deg) scale(${(props) => props.scale});
		height: 450px;
		width: auto;
	}
`

export default Image
