import { filter, map } from 'lodash'
import { TimelinesActionProps, TimelinesActions, TimelinesStateProps } from './types'

const reducer = (state: TimelinesStateProps, action: TimelinesActionProps) => {
	switch (action.type) {
		case TimelinesActions.fetch:
			return {
				...state,
				data: action.payload,
				isLoading: false
			}

		case TimelinesActions.add:
			return {
				...state,
				data: [action.payload, ...state.data],
				isLoading: false
			}

		case TimelinesActions.remove:
			return {
				...state,
				data: filter(state.data, (value) => value?.id !== action.payload),
				isLoading: false
			}

		case TimelinesActions.update:
			return {
				...state,
				data: map(state.data, (value) => {
					if (value?.id === action.payload.id) {
						return {
							...value,
							...action.payload
						}
					}

					return value
				}),
				isLoading: false
			}

		case TimelinesActions.updateLoading:
			return {
				...state,
				isLoading: action.payload
			}

		case TimelinesActions.updateCount:
			return {
				...state,
				count: action.payload
			}

		default:
			return state
	}
}

export default reducer
