import SideNavigation from 'components/block/navigation/side'
import TopNavigation from 'components/block/navigation/top'
import { FC, ReactNode, useState } from 'react'
import styled from 'styled-components/macro'

export type MainLayoutProps = {
	children: ReactNode
}

const Layout: FC<MainLayoutProps> = ({ children }) => {
	const [isvisible, setIsVisible] = useState<boolean>(false)
	return (
		<Main>
			<SideNavigation />
			<MainWrapper>
				<TopNavigation onToggle={() => setIsVisible} />
				<Wrapper>{children}</Wrapper>
			</MainWrapper>
		</Main>
	)
}

const Main = styled.main`
	background-color: ${(props) => props.theme.colors.neutral005};
	display: flex;
	min-height: 100vh;
`

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	margin-left: 0;

	@media screen and (min-width: 768px) {
		margin-left: 101px;
	}
`

const Wrapper = styled.div`
	flex: 1;
	margin-top: 50px;
	@media screen and (min-width: 768px) {
		margin-top: 70px;
	}
`

export default Layout
