import { FC, FormEvent, useState } from 'react'
import styled, { css } from 'styled-components/macro'
import DatePicker from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import { isNil } from 'lodash'
import { transparentize } from 'polished'

export type DatePickerProps = {
	error?: string
	isBlocked?: boolean
	label?: string
	labelBackground?: string
	placeholder?: string
	value: Date
	width?: string
	onBlur?: (e: FormEvent<HTMLInputElement>) => void
	onChange: (date: Date) => void
	onFocus?: (e: FormEvent<HTMLInputElement>) => void
}

const DateTimePicker: FC<DatePickerProps> = ({
	error,
	isBlocked = false,
	label,
	labelBackground = 'inherit',
	placeholder,
	value,
	width,
	onBlur,
	onChange,
	onFocus,
	...rest
}: DatePickerProps) => {
	const [isFocus, setIsFocus] = useState<boolean>(false)

	return (
		<Wrapper
			{...rest}
			isBlocked={isBlocked}
			hasError={Boolean(error)}
			hasLabel={Boolean(label)}
			isFocus={isFocus}
			width={width}>
			<InputWrapper
				dateFormat='LLLL, dd, yyyy'
				selected={value}
				maxDate={new Date()}
				onChange={(date: Date) => {
					setIsFocus(false)
					onChange(date)
				}}
				onBlur={(e) => {
					setIsFocus(false)
					onBlur && onBlur(e)
				}}
				onFocus={(e) => {
					setIsFocus(true)
					onFocus && onFocus(e)
				}}
			/>
			{label && (
				<Label
					background={labelBackground}
					isActive={!isNil(value) || isFocus || Boolean(placeholder)}>
					{label}
				</Label>
			)}
			<Error>{error}</Error>
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	isBlocked: boolean
	hasError: boolean
	hasLabel: boolean
	isFocus: boolean
	width?: string
}>`
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	border: 2px solid
		${(props) =>
			props.isFocus ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	box-shadow: none;
	display: flex;
	flex-direction: column-reverse;
	height: 50px;
	padding: 11px 15px;
	position: relative;
	text-align: left;
	transition: all 250ms ease-in-out;
	width: ${(props) =>
		props.isBlocked ? '100%' : props.width ? props.width : 'fit-content'};

	& .react-datepicker-popper {
		z-index: 100;
	}
	& .react-datepicker {
		background: ${(props) => props.theme.colors.neutral001};
		border: none;
		border-radius: 12px;
		box-shadow: 0 7px 12px
			${(props) => transparentize(0.75, props.theme.colors.neutral000)};
		color: ${(props) => props.theme.colors.neutral001};
		font-family: ${(props) => props.theme.font.family.default};
		height: 400px;
		padding: 20px;
	}

	& .react-datepicker__triangle {
		display: none;
	}

	& .react-datepicker__header {
		background: none;
		border-bottom: 0;
		border-top-left-radius: 12px;
		border-top-right-radius: 12px;
	}

	& .react-datepicker__navigation {
		top: 20px;
		&-icon {
			&::before {
				border-color: ${(props) => props.theme.colors.neutral000};
				border-width: 2px 2px 0 0;
			}
			&:hover {
				&::before {
					border-color: ${(props) => props.theme.colors.primary};
				}
			}
		}

		&--previous {
			left: 20px;
		}
		&--next {
			right: 20px;
		}
	}

	& .react-datepicker__current-month {
		color: ${(props) => props.theme.colors.neutral000};
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.04em;
		padding: 0 20px 20px 20px;
	}

	& .react-datepicker__day-names {
		display: flex;
	}

	& .react-datepicker__day-name {
		align-items: center;
		color: ${(props) => props.theme.colors.neutral000};
		display: flex;
		flex: 1;
		font-size: 18px;
		font-weight: 600;
		justify-content: center;
		letter-spacing: 0.04em;

		width: 40px;
		height: 40px;
	}

	& .react-datepicker__week {
		display: flex;
	}

	& .react-datepicker__day {
		align-items: center;
		justify-content: center;
		display: flex;
		border: 1px solid ${(props) => props.theme.colors.neutral001};
		border-radius: 7px;
		color: ${(props) => props.theme.colors.neutral002};
		flex: 1;
		font-size: 18px;
		font-weight: 400;
		width: 40px;
		height: 40px;

		&:hover {
			background: ${(props) => props.theme.colors.neutral005};
			border-radius: 7px;
			color: ${(props) => props.theme.colors.neutral000};
		}

		&--outside-month {
			color: ${(props) => props.theme.colors.neutral003};
		}

		&--keyboard-selected {
			background: transparent;
			border: 1px solid ${(props) => props.theme.colors.primary};
			color: ${(props) => props.theme.colors.primary};
			outline: none;
		}

		&--selected {
			background-color: ${(props) => props.theme.colors.neutral000};
			color: ${(props) => props.theme.colors.neutral001};
			&:hover {
				background-color: ${(props) => props.theme.colors.neutral002};
				color: ${(props) => props.theme.colors.neutral001};
			}
		}

		&--disabled {
			color: ${(props) => props.theme.colors.neutral005};
			&:hover {
				background: transparent;
				color: ${(props) => props.theme.colors.neutral005};
			}
		}
	}

	& .react-datepicker__month {
		margin: 0;
		&-container {
			width: 320px;
		}
	}
`

const Label = styled.label<{ isActive: boolean; background: string }>`
	background: ${(props) =>
		props.background ? props.background : props.theme.colors.neutral005};
	color: ${(props) =>
		props.isActive ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	font-size: 18px;
	left: 10px;
	padding: 0 5px;
	position: absolute;
	top: 14px;
	transform-origin: left;
	transition: all 250ms ease-in-out;
	z-index: 0;
	${(props) =>
		props.isActive &&
		css`
			top: -10px;
			transform: scale(0.8);
		`}
`

const InputWrapper = styled(DatePicker)`
	background: none;
	border-radius: 7px;
	color: ${(props) => props.theme.colors.neutral000};
	font-family: ${(props) => props.theme.font.family.default};
	font-size: 18px;
	outline: none;
	z-index: 1;
	width: 100%;
`
const Error = styled.div`
	bottom: -15px;
	color: ${(props) => props.theme.colors.danger};
	font-size: 18px;
	font-weight: 600;
	left: 0;
	letter-spacing: 0.02em;
	position: absolute;
`
export default DateTimePicker
