import { blinkSpin, fadeIn, scaleUp } from 'components/keyframes'
import { ReactComponent as CircleIcon } from 'icons/spinner.svg'
import { isNil } from 'lodash'
import { FC, ReactNode } from 'react'
import styled, { css } from 'styled-components/macro'

type ButtonVariant = 'primary' | 'secondary' | 'danger' | 'secondaryDanger'

export type ButtonProps = {
	children: ReactNode
	className?: string
	disabled?: boolean
	emphasis?: boolean
	icon?: ReactNode
	isBlocked?: boolean
	isLoading?: boolean
	onClick?: () => void
	rounded?: boolean
	type?: 'button' | 'submit'
	variant?: ButtonVariant
	width?: string
}

const Button: FC<ButtonProps> = ({
	children,
	disabled,
	emphasis = false,
	icon,
	isBlocked,
	isLoading,
	rounded = false,
	type = 'button',
	variant = 'primary',
	width,
	onClick,
	...rest
}) => {
	return (
		<Wrapper
			rounded={rounded}
			type={type}
			variant={variant}
			onClick={onClick}
			width={width}
			isBlocked={isBlocked}
			disabled={disabled}
			{...rest}>
			{isLoading ? (
				<Loader>
					<CircleIcon />
				</Loader>
			) : (
				<>
					{!isNil(icon) && <Icon emphasis={emphasis}>{icon}</Icon>}
					<Text emphasis={emphasis}>{children}</Text>
				</>
			)}
		</Wrapper>
	)
}

const Wrapper = styled.button<{
	isBlocked?: boolean
	rounded: boolean
	variant: ButtonVariant
	width?: string
}>`
	background: ${(props) => {
		switch (props.variant) {
			case 'secondaryDanger':
				return props.theme.colors.neutral004
			case 'danger':
				return props.theme.colors.danger
			case 'secondary':
				return props.theme.colors.neutral004
			default:
				return 'linear-gradient(106.17deg, #F05E52 38.76%, #F07852 84.11%)'
		}
	}};
	border-radius: ${(props) => (props.rounded ? '100px' : '12px')};
	color: ${(props) => {
		switch (props.variant) {
			case 'secondaryDanger':
				return props.theme.colors.danger
			case 'secondary':
				return props.theme.colors.neutral000
			default:
				return props.theme.colors.neutral001
		}
	}};
	align-items: center;
	cursor: pointer;
	display: flex;
	height: 60px;
	padding: 20px;
	justify-content: center;
	width: ${(props) => (props.width ? props.width : props.isBlocked ? '100%' : 'auto')};
	&:hover {
		opacity: 0.75;
	}
	&:active {
		opacity: 0.5;
	}

	&:disabled {
		opacity: 0.5;
		cursor: default;
		&:hover {
			opacity: 0.5;
		}
	}
`

const Icon = styled.span<{ emphasis: boolean }>`
	margin-right: 10px;
	${(props) =>
		props.emphasis &&
		css`
			animation: ${fadeIn} 500ms ease-in-out, ${scaleUp} 250ms linear;};
		`}
`

const Text = styled.span<{ emphasis: boolean }>`
	font-size: 18px;
	font-weight: 600;
	line-height: 22px;
	${(props) =>
		props.emphasis &&
		css`
			animation: ${fadeIn} 500ms ease-in-out;
		`}
`

const Loader = styled.div`
	& > svg {
		animation: ${blinkSpin} 1s linear infinite;
	}
`

export default Button
