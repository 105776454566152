import { DocumentReference } from 'firebase/firestore'
import { TimelineProps } from 'providers/timelines/types'

export enum UsersActions {
	add = 'ADD',
	fetch = 'FETCH',
	updateLoading = 'UPDATE_LOADING'
}

export type UsersActionProps =
	| { type: UsersActions.add; payload: UserProps }
	| { type: UsersActions.fetch; payload: UserProps[] }
	| { type: UsersActions.updateLoading; payload: boolean }

export type UserProps = {
	createdAt: Date
	displayName: string
	email: string
	firstname: string
	id: string
	isLoading?: boolean
	isVerified: boolean
	language: string
	lastname: string
	password?: string
	role: number
	status: string
	currentTimeline: (Partial<TimelineProps> & { ref: DocumentReference }) | null
	sharedTimelines: Array<TimelineProps>
}

export type UsersStateProps = {
	data: Array<UserProps>
	isLoading: boolean
}
