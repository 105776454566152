import Loader from 'components/block/loader'
import Button from 'components/element/button'
import Datalist, { DatalistOption } from 'components/element/datalist'
import Input from 'components/element/input'
import Select from 'components/element/select'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { ReactComponent as DeleteIcon } from 'icons/delete.svg'
import { filter, find, isNil, map } from 'lodash'
import { useMeState } from 'providers/me'
import { useTimelinesDispatch, useTimelinesState } from 'providers/timelines'
import { TimelineProps, TimelinesActions } from 'providers/timelines/types'
import { useUsersState } from 'providers/users'
import { UserProps } from 'providers/users/types'
import { useSearchUsers } from 'providers/users/useSearchUsers'
import { FC, FormEvent, useEffect, useState } from 'react'
import styled, { useTheme } from 'styled-components/macro'

export type InviteeProps = {
	id: string
	email: string
	name: string
	hasAccepted: boolean
	accessType: string
}

const Invites: FC = ({}) => {
	const theme = useTheme()
	const me = useMeState()

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false)
	const [email, setEmail] = useState<string>('')
	const [accessType, setAccessType] = useState<'r' | 'w'>('r')
	const [invitees, setInvitees] = useState<Array<InviteeProps>>([])
	const [inviteeToDelete, setInviteeToDelete] = useState<InviteeProps>()
	const [userToInvite, setUserToInvite] = useState<UserProps>()
	const [search, setSearch] = useState<string>('')

	const { data: timelines, isLoading: isTimelineLoading } = useTimelinesState()
	const timelineDispatch = useTimelinesDispatch()
	const { data: users, isLoading: isSearchLoading } = useUsersState()

	useSearchUsers({ search })

	const deleteInvitee = async (invitee: InviteeProps) => {
		if (isNil(me?.currentTimeline)) {
			return
		}

		setInviteeToDelete(invitee)
		setIsDeleteLoading(true)
		try {
			const currentTimeline = find(timelines, (tl: TimelineProps) => {
				return tl.id === me.currentTimeline?.id
			})

			if (isNil(currentTimeline)) {
				return
			}

			const filteredInvitees = filter(
				currentTimeline?.invitees,
				(currentTimelineInvitee: InviteeProps) => {
					return currentTimelineInvitee.email !== invitee.email
				}
			)

			const formattedTimeline = {
				id: currentTimeline?.id,
				image: currentTimeline?.image,
				name: currentTimeline?.name,
				description: currentTimeline?.description,
				createdAt: currentTimeline?.createdAt,
				updatedAt: new Date(),
				invitees: filteredInvitees
			}

			const timelineRef = doc(db, 'timelines', currentTimeline.id)
			const userRef = doc(db, 'users', invitee.id)

			const userSnap = await getDoc(userRef)
			const userData = userSnap.data() as UserProps

			await setDoc(timelineRef, formattedTimeline, { merge: true })

			const formatSharedTimelines = filter(
				userData.sharedTimelines,
				(sharedTimeline) => sharedTimeline.id !== currentTimeline.id
			)
			if (userSnap.exists()) {
				await setDoc(
					userRef,
					{
						sharedTimelines: formatSharedTimelines
					},
					{ merge: true }
				)
			}
			setInviteeToDelete(undefined)
			setIsDeleteLoading(false)

			timelineDispatch({
				type: TimelinesActions.update,
				payload: {
					...currentTimeline,
					invitees: filteredInvitees
				}
			})
		} catch (error) {
			setInviteeToDelete(undefined)
			setIsDeleteLoading(false)
			console.error(error)
		}
	}

	const sendInvite = async (e: FormEvent) => {
		e.preventDefault()
		setIsLoading(true)

		if (isNil(me?.currentTimeline)) {
			return
		}

		try {
			const currentTimeline = find(timelines, (tl: TimelineProps) => {
				return tl.id === me?.currentTimeline?.id
			})

			if (isNil(currentTimeline)) {
				return
			}

			if (isNil(userToInvite)) {
				return
			}

			const timelineRef = doc(db, 'timelines', currentTimeline.id)
			// update invitees
			const invitees = currentTimeline.invitees
				? [
						{
							id: userToInvite?.id,
							name: `${userToInvite.firstname} ${userToInvite.lastname}`,
							email,
							hasAccepted: false,
							accessType
						},
						...currentTimeline?.invitees
				  ]
				: [
						{
							id: userToInvite?.id,
							name: `${userToInvite.firstname} ${userToInvite.lastname}`,
							email,
							hasAccepted: false,
							accessType
						}
				  ]

			const formattedCurrentTimeline = {
				id: currentTimeline.id,
				image: currentTimeline.image,
				name: currentTimeline.name,
				description: currentTimeline.description,
				createdAt: currentTimeline.createdAt,
				createdBy: {
					createdAt: currentTimeline.createdBy.createdAt,
					displayName: currentTimeline.createdBy.displayName,
					email: currentTimeline.createdBy.email,
					firstname: currentTimeline.createdBy.firstname,
					id: currentTimeline.createdBy.id,
					isLoading: currentTimeline.createdBy.isLoading,
					language: currentTimeline.createdBy.language,
					lastname: currentTimeline.createdBy.lastname,
					role: currentTimeline.createdBy.role,
					status: currentTimeline.createdBy.status
				},
				updatedAt: currentTimeline.updatedAt,
				invitees: currentTimeline.invitees
			}

			const sharedTimelines = userToInvite.sharedTimelines
				? [formattedCurrentTimeline, ...userToInvite.sharedTimelines]
				: [formattedCurrentTimeline]

			const userToInviteRef = doc(db, 'users', userToInvite.id)

			await setDoc(
				timelineRef,
				{
					updatedAt: new Date(),
					invitees
				},
				{ merge: true }
			)

			// 	//add sharedtimeline to the user
			await setDoc(
				userToInviteRef,
				{
					sharedTimelines
				},
				{ merge: true }
			)

			timelineDispatch({
				type: TimelinesActions.update,
				payload: {
					...currentTimeline,
					invitees
				}
			})

			setIsLoading(false)
			setEmail('')
			setAccessType('r')
		} catch (error: any) {
			setIsLoading(false)
			console.error(error)
		}
	}

	const selectUserToInvite = (user: UserProps) => () => {
		setEmail(user.email)
		setUserToInvite(user)
	}

	//fetch current timeline from the existing timeline list
	useEffect(() => {
		if (me?.currentTimeline?.invitees) {
			const currentTimeline = find(timelines, (tl: TimelineProps) => {
				return tl.id === me.currentTimeline?.id
			})

			if (isNil(currentTimeline)) {
				return
			}
			setInvitees(currentTimeline?.invitees)
		}
	}, [me.currentTimeline, timelines])

	//call search after user type
	useEffect(() => {
		const timeOutId = setTimeout(async () => {
			if (email.length <= 2) {
				return
			}

			setSearch(email)
		}, 500)
		return () => clearTimeout(timeOutId)
	}, [email])

	return (
		<Wrapper>
			<form onSubmit={sendInvite}>
				<Title>Grant Access</Title>
				<Input
					autoComplete='nope'
					type='email'
					label='Email'
					list={
						<Datalist
							isLoading={isSearchLoading}
							id='emails'
							list={users}
							option={(user: UserProps, index: number) => {
								return (
									<DatalistOption key={index} onClick={selectUserToInvite(user)}>
										<strong>{`${user.firstname} ${user.lastname} `}</strong>
										{`(${user.email})`}
									</DatalistOption>
								)
							}}></Datalist>
					}
					value={email}
					labelBackground={theme.colors.neutral001}
					onChange={(e) => {
						setEmail(e.currentTarget.value)
					}}
				/>

				<Select
					value={accessType}
					onChange={(e) => setAccessType(e.currentTarget.value as 'r' | 'w')}
					label='Access Type'>
					<option value='r'>Read</option>
					<option value='w'>Write</option>
				</Select>
				<Button type='submit' isLoading={isLoading} isBlocked>
					Send
				</Button>
			</form>
			<InviteWrapper>
				<InvitedTitle>Access</InvitedTitle>
				{!Boolean(invitees?.length) && isTimelineLoading && (
					<Empty>
						<Loader width='24px' height='24px' type='spinnerOnly' />
					</Empty>
				)}
				{!Boolean(invitees?.length) && !isTimelineLoading && (
					<Empty>Only you can access this timeline right now</Empty>
				)}
				{Boolean(invitees?.length) &&
					map(invitees, (invitee, index) => (
						<Invitee key={index}>
							<User>
								<Name>{invitee.name} </Name>
								<Email>({invitee.email})</Email>
							</User>
							<AccessType type={invitee.accessType}>
								{invitee.accessType === 'r' ? 'Read' : 'Write'}
							</AccessType>
							{invitee.email === inviteeToDelete?.email ? (
								<>
									{isDeleteLoading ? (
										<Loader width='20px' height='20px' type='spinnerOnly' />
									) : (
										<Delete onClick={() => deleteInvitee(invitee)}>
											<DeleteIcon />
										</Delete>
									)}
								</>
							) : (
								<Delete onClick={() => deleteInvitee(invitee)}>
									<DeleteIcon />
								</Delete>
							)}
						</Invitee>
					))}
			</InviteWrapper>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;

	& > * {
		margin-bottom: 10px;
	}

	& > form > * {
		margin-bottom: 10px;
	}
`

const Title = styled.div<{ isDanger?: boolean }>`
	color: ${(props) =>
		props.isDanger ? props.theme.colors.danger : props.theme.colors.neutral000};
	font-weight: 600;
	font-size: 20px;
	flex: 1;
`

const InviteWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 24px;
`

const Invitee = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.neutral004};
	display: flex;
	align-items: center;
	padding: 18px 0;
	&:last-child {
		border-bottom: none;
	}
`

const InvitedTitle = styled.span`
	font-weight: 600;
	font-size: 16px;
	margin-bottom: 12px;
`

const Empty = styled.span`
	display: flex;
	padding: 24px 0;
	text-align: center;
	width: 100%;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.neutral005};
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 14px;
	border-radius: 7px;
`

const User = styled.span`
	display: flex;
	flex-direction: column;
	margin-right: auto;
`

const Name = styled.span`
	font-weight: 600;
	font-size: 16px;
`

const Email = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
`

const AccessType = styled.span<{ type: string }>`
	padding: 3px 7px;
	border-radius: 3px;
	width: 50px;
	background-color: ${(props) =>
		props.type === 'r' ? props.theme.colors.neutral005 : props.theme.colors.neutral000};
	color: ${(props) =>
		props.type === 'r' ? props.theme.colors.neutral000 : props.theme.colors.neutral001};
	margin-right: 12px;
`

const Delete = styled.button`
	background: none;
	border: none;
	cursor: pointer;
	padding: 0;
	&:hover {
		opacity: 0.75;
	}
	&:active {
		opacity: 0.5;
	}
`

export default Invites
