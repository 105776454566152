import { filter, map } from 'lodash'
import { MomentsActionProps, MomentsActions, MomentsStateProps } from './types'

const reducer = (state: MomentsStateProps, action: MomentsActionProps) => {
	switch (action.type) {
		case MomentsActions.fetch:
			const payload = [...action.payload]

			if (state.data.length > 0) {
				payload.shift()
			}

			return {
				...state,
				data: [...state.data, ...payload],
				isLoading: false
			}

		case MomentsActions.fetchNew:
			return {
				...state,
				data: action.payload,
				isLoading: false
			}

		case MomentsActions.add:
			return {
				...state,
				data: [action.payload, ...state.data],
				isLoading: false
			}

		case MomentsActions.remove:
			return {
				...state,
				data: filter(state.data, (value) => value?.id !== action.payload),
				isLoading: false
			}

		case MomentsActions.update:
			return {
				...state,
				data: map(state.data, (value) => {
					if (value?.id === action.payload.id) {
						return {
							...value,
							description: action.payload.description
						}
					}

					return value
				}),
				isLoading: false
			}

		case MomentsActions.updateLoading:
			return {
				...state,
				isLoading: action.payload
			}

		case MomentsActions.updatehasMore:
			return {
				...state,
				hasMore: action.payload
			}

		default:
			return state
	}
}

export default reducer
