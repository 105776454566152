import { ReactComponent as CircleIcon } from 'icons/spinner.svg'
import { FC } from 'react'
import styled, { css, keyframes } from 'styled-components/macro'

export type LoaderProps = {
	isDarkedTheme?: boolean
	type?: 'fill' | 'fullscreen' | 'spinnerOnly'
	width?: string
	height?: string
}

const Loader: FC<LoaderProps> = ({
	type = 'fullscreen',
	isDarkedTheme,
	width = '30px',
	height = '30px'
}) => {
	return (
		<Wrapper type={type}>
			<Spinner width={width} height={height} isDarkedTheme={isDarkedTheme}>
				<CircleIcon />
			</Spinner>
		</Wrapper>
	)
}

const Wrapper = styled.div<{ type: string }>`
	display: flex;
	align-items: center;
	justify-content: center;
	${(props) => {
		switch (props.type) {
			case 'fullscreen':
				return css`
					height: 100vh;
					width: 100vw;
					position: fixed;
					z-index: 1000;
					top: 0;
					left: 0;
				`
			case 'fill':
				return css`
					width: 100%;
					padding-bottom: 60px;
					padding-top: 60px;
				`

			case 'spinnerOnly':
				return css`
					width: auto;
					padding: 0;
					margin: 0;
				`
		}
	}}
`

const blinkSpin = keyframes`
	0% {
		opacity: 1;
		transform: rotate(0deg);
	}
	
	50% {
		opacity: 0.15;
		transform: rotate(360deg);

	}

	100% {
		opacity: 1;
		transform: rotate(0deg);
	}
`

const Spinner = styled.div<{ width: string; height: string; isDarkedTheme?: boolean }>`
	& > svg {
		animation: ${blinkSpin} 1s linear infinite;
		color: ${(props) =>
			props.isDarkedTheme
				? props.theme.colors.neutral001
				: props.theme.colors.neutral000};
		height: ${(props) => props.height};
		width: ${(props) => props.width};
	}
`

export default Loader
