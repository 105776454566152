import { InviteeProps } from 'pages/settings/invites'
import { UserProps } from 'providers/users/types'

export enum TimelinesActions {
	add = 'ADD',
	fetch = 'FETCH',
	remove = 'REMOVE',
	update = 'UPDATE',
	updateCount = 'UPDATE_COUNT',
	updateLoading = 'UPDATE_LOADING'
}

export type TimelinesActionProps =
	| { type: TimelinesActions.add; payload: TimelineProps }
	| { type: TimelinesActions.fetch; payload: TimelineProps[] }
	| { type: TimelinesActions.remove; payload: string }
	| { type: TimelinesActions.update; payload: TimelineProps }
	| { type: TimelinesActions.updateCount; payload: number }
	| { type: TimelinesActions.updateLoading; payload: boolean }

export type TimelineProps = {
	id: string
	image: string
	name: string
	description: string
	createdAt: Date
	createdBy: UserProps
	updatedAt: Date
	invitees: Array<InviteeProps>
}

export type TimelinesStateProps = {
	data: Array<TimelineProps>
	isLoading: boolean
	count: number
}
