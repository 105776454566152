import { map } from 'lodash'
import { Dispatch, FC, SetStateAction } from 'react'
import styled from 'styled-components/macro'
import Theme, { ThemeProps } from './theme'

export type ThemeSelectorProps = {
	value: string
	themes: Array<ThemeProps>
	onChange: Dispatch<SetStateAction<string>>
}

const ThemeSelector: FC<ThemeSelectorProps> = ({ themes, value, onChange }) => {
	return (
		<Wrapper>
			<Title>Select Theme</Title>
			<Themes>
				{map(themes, (theme, index) => {
					return (
						<Theme
							key={index}
							theme={theme}
							isActive={value === theme.id}
							onClick={() => {
								onChange(theme.id)
							}}
						/>
					)
				})}
			</Themes>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 40px;
`

const Title = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 16px;
	font-weight: 600;
	margin-bottom: 10px;
`

const Themes = styled.div`
	display: flex;
	flex-wrap: wrap;
`

export default ThemeSelector
