import { keyframes } from 'styled-components/macro'

export const blink = keyframes`
0% {
    opacity: 1;
}

50% {
    opacity: 0.15;

}

100% {
    opacity: 1;
}
`

export const fadeIn = keyframes`
0% {
    opacity: 0;
}

100% {
    opacity: 1;
}
`

export const scaleUp = keyframes`
0% {
    transform: scale(.5);
	opacity: 1;
}

100% {
    transform: scale(1.25);
	opacity: 0.5;
}
`

export const blinkSpin = keyframes`
	0% {
		opacity: 1;
		transform: rotate(0deg);
	}
	
	50% {
		opacity: 0.15;
		transform: rotate(360deg);

	}

	100% {
		opacity: 1;
		transform: rotate(0deg);
	}
`
