import { collection, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { map } from 'lodash'
import { useMeState } from 'providers/me'
import { useEffect, useState } from 'react'
import { useUsersDispatch } from '.'
import { UserProps, UsersActions } from './types'

export type UseFetchUserProps = {
	search: string
}

export const useSearchUsers = ({ search }: UseFetchUserProps) => {
	const FETCH_LENGTH = 20

	const userDispatch = useUsersDispatch()
	const me = useMeState()

	const [data, setData] = useState<Array<UserProps>>([])

	useEffect(() => {
		const fn = async () => {
			if (!me?.currentTimeline?.id || !search) {
				return
			}

			try {
				userDispatch({ type: UsersActions.updateLoading, payload: true })

				const usersRef = collection(db, 'users')
				const usersQuery = query(
					usersRef,
					orderBy('email'),
					where('email', '>=', search),
					where('email', '<=', search + '\uf8ff'),
					limit(FETCH_LENGTH)
				)

				const usersSnapshot = await getDocs(usersQuery)
				const payload: Array<UserProps> = map(usersSnapshot.docs, (doc) => {
					return {
						...(doc.data() as UserProps),
						id: doc.id
					}
				})

				setData(payload)
				userDispatch({
					type: UsersActions.fetch,
					payload
				})
			} catch (error: any) {
				console.error(error)
				userDispatch({ type: UsersActions.updateLoading, payload: false })
			}
		}

		fn()

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [search, me?.id])

	return [data]
}
