import { UsersActionProps, UsersActions, UsersStateProps } from './types'

const reducer = (state: UsersStateProps, action: UsersActionProps) => {
	switch (action.type) {
		case UsersActions.fetch:
			return {
				...state,
				data: action.payload,
				isLoading: false
			}

		case UsersActions.add:
			return {
				...state,
				data: [action.payload, ...state.data],
				isLoading: false
			}

		case UsersActions.updateLoading:
			return {
				...state,
				isLoading: action.payload
			}

		default:
			return state
	}
}

export default reducer
