import { getDownloadURL, ref } from 'firebase/storage'
import { storage } from 'firebaseConfig'
import { map } from 'lodash'
import { useEffect, useState } from 'react'

export type UseFetchPhotosProps = {
	photos: Array<string>
	userId: string
}

const useFetchPhotos = ({ photos, userId }: UseFetchPhotosProps) => {
	const [isLoading, setIsLoading] = useState<boolean>()
	const [photoUrls, setPhotoUrls] = useState<Array<string>>([])

	useEffect(() => {
		const fn = async () => {
			setIsLoading(true)
			if (Boolean(photos?.length)) {
				const urls = await Promise.all(
					map(photos, (photo) => {
						const photoRef = ref(storage, `moments/${userId}/${photo}`)

						return getDownloadURL(photoRef)
					})
				)

				setIsLoading(false)
				setPhotoUrls(urls)
			} else {
				setIsLoading(false)
				setPhotoUrls([])
			}
		}

		fn()
	}, [photos, userId])

	return { photoUrls, isLoading }
}

export default useFetchPhotos
