import DeleteConfirmation from 'components/block/deleteConfirmation'
import Button from 'components/element/button'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { filter } from 'lodash'
import { useMeDispatch, useMeState } from 'providers/me'
import { MeActions } from 'providers/me/types'
import { useTimelinesDispatch, useTimelinesState } from 'providers/timelines'
import { TimelinesActions } from 'providers/timelines/types'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components/macro'
import { setCurrentTimeline } from 'utils'

const DeleteTimeline = () => {
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isConfirmationOpen, setIsConfirmationOpen] = useState<boolean>(false)

	const me = useMeState()
	const { data: timelines } = useTimelinesState()
	const timelineDispatch = useTimelinesDispatch()
	const meDispatch = useMeDispatch()

	const deleteTimeline = async () => {
		if (me?.currentTimeline?.id) {
			try {
				setIsLoading(true)
				timelineDispatch({ type: TimelinesActions.updateLoading, payload: true })

				const timelineRef = doc(db, 'timelines', me.currentTimeline.id)
				await deleteDoc(timelineRef)
				timelineDispatch({
					type: TimelinesActions.remove,
					payload: me.currentTimeline.id
				})

				const _newTimelines = filter(timelines, (tl) => tl.id !== me?.currentTimeline?.id)
				const timeline = _newTimelines[0]
				await setCurrentTimeline({
					timeline: timeline,
					userId: me.id,
					updated: ({ timelineRef }) => {
						meDispatch({
							type: MeActions.fetch,
							payload: {
								...me,
								currentTimeline: {
									id: timeline.id,
									image: timeline.image,
									name: timeline.name,
									description: timeline.description,
									createdAt: timeline.createdAt,
									updatedAt: timeline.updatedAt,
									invitees: timeline.invitees,
									ref: timelineRef
								}
							}
						})
					}
				})

				setIsLoading(false)
				setIsConfirmationOpen(false)
				navigate('/timeline')
			} catch (error: any) {
				setIsLoading(false)
				console.error(error)
			}
		}
	}
	return (
		<Wrapper>
			<DeleteConfirmation
				isLoading={isLoading}
				isOpen={isConfirmationOpen}
				onDelete={deleteTimeline}
				setIsOpen={setIsConfirmationOpen}
			/>
			<Title isDanger>Delete Timeline</Title>
			<Text>Be careful! You will not be able to recover this timeline once delete. </Text>
			<Button variant='danger' onClick={() => setIsConfirmationOpen(true)}>
				Delete Timeline
			</Button>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 60px;

	& > * {
		margin-bottom: 10px;
	}
`

const Title = styled.div<{ isDanger?: boolean }>`
	color: ${(props) =>
		props.isDanger ? props.theme.colors.danger : props.theme.colors.neutral000};
	font-weight: 600;
	font-size: 20px;
	flex: 1;
`

const Text = styled.div`
	color: ${(props) => props.theme.colors.neutral003};
	font-weight: 400;
	font-size: 18px;
	flex: 1;
`

export default DeleteTimeline
