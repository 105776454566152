import { ReactComponent as BackIcon } from 'icons/back.svg'
import { ReactComponent as ForwardIcon } from 'icons/forward.svg'
import { ReactComponent as CloseIcon } from 'icons/times.svg'
import { reverse } from 'lodash'
import { transparentize } from 'polished'
import { Dispatch, FC, SetStateAction, useState } from 'react'
import styled from 'styled-components/macro'

export type PhotoViewerProps = {
	isVisible: boolean
	photos: Array<string>
	onClose: Dispatch<SetStateAction<boolean>>
}

const PhotoViewer: FC<PhotoViewerProps> = ({ isVisible, photos, onClose }) => {
	const [currentPhoto, setCurrentPhoto] = useState<number>(0)
	const reversedPhotos = reverse([...photos])

	const onPhotoViewerClose = () => {
		onClose(false)
		setCurrentPhoto(0)
	}

	if (!isVisible) return null

	const updatedCurrentPhoto = (isback?: boolean) => () => {
		if (isback) {
			if (currentPhoto < 1) {
				setCurrentPhoto(reversedPhotos.length - 1)
			} else {
				setCurrentPhoto((state) => state - 1)
			}
		} else {
			if (currentPhoto < reversedPhotos.length - 1) {
				setCurrentPhoto((state) => state + 1)
			} else {
				setCurrentPhoto(0)
			}
		}
	}

	return (
		<Wrapper>
			<Header>
				<Close onClick={onPhotoViewerClose}>
					<CloseIcon />
				</Close>
			</Header>

			<PhotoWrapper>
				<Photo src={reversedPhotos[currentPhoto]} />
			</PhotoWrapper>

			<Navs>
				<Count>
					{currentPhoto + 1} of {photos.length}
				</Count>
				<Nav onClick={updatedCurrentPhoto(true)}>
					<BackIcon />
				</Nav>
				<Nav onClick={updatedCurrentPhoto(false)}>
					<ForwardIcon />
				</Nav>
			</Navs>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: center;
	backdrop-filter: blur(3px);
	background-color: ${(props) => transparentize(0.05, props.theme.colors.neutral000)};
	color: ${(props) => props.theme.colors.neutral001};
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: ${(props) => props.theme.indexes.backdrop};
	padding-bottom: 24px;
`

const Header = styled.div`
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 24px 12px;
	@media screen and (min-width: 768px) {
		padding: 24px;
	}
`

const PhotoWrapper = styled.div`
	overflow: hidden;
	margin: 0 12px;
	flex: 1;
	display: flex;
`

const Photo = styled.img`
	width: 100%;
	height: 100%;
	border-radius: 7px;
	object-fit: contain;
`

const Nav = styled.span`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 48px;
	color: ${(props) => props.theme.colors.neutral000};
	cursor: pointer;
	display: flex;
	height: 48px;
	justify-content: center;
	width: 48px;
	margin-left: 12px;

	&:hover {
		opacity: 0.75;
	}
	&:active {
		opacity: 0.5;
	}
`

const Close = styled.button`
	align-items: center;
	display: flex;
	height: 35px;
	justify-content: center;
	width: 35px;
	cursor: pointer;
	border-radius: 100%;
	&:hover {
		opacity: 0.75;
	}
	&:active {
		opacity: 0.5;
	}
`

const Count = styled.div`
	display: flex;
	font-size: 16px;
	color: ${(props) => props.theme.colors.neutral003};
	margin-right: auto;
`

const Navs = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding: 24px 12px;
	margin-top: auto;
	@media screen and (min-width: 768px) {
		padding: 24px;
	}
`
export default PhotoViewer
