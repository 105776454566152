import Context, { ContextMenuItemType } from 'components/block/contextMenu'
import DeleteConfirmation from 'components/block/deleteConfirmation'
import Loader from 'components/block/loader'
import { deleteDoc, doc } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { ReactComponent as EllipsesIcon } from 'icons/ellipses.svg'
import { map } from 'lodash'
import { DateTime, Info } from 'luxon'
import { transparentize } from 'polished'
import { useMeState } from 'providers/me'
import { useMomentsDispatch } from 'providers/moments'
import { MomentsActions } from 'providers/moments/types'
import { FC, useState } from 'react'
import styled from 'styled-components/macro'
import { limitString } from 'utils'
import useFetchPhotos from './useFetchPhotos'

export type MomentProps = {
	id: string
	date: Date
	description: string
	photos: Array<string>
	title: string
	onClick: (photos: Array<string>) => void
	onEdit: () => void
}

const Moment: FC<MomentProps> = ({
	id,
	date,
	description,
	photos,
	title,
	onClick,
	onEdit
}) => {
	const DESCRIPTION_LIMIT = 160
	const TITLE_LIMIT = 200

	const dt = DateTime.fromJSDate(date)
	const me = useMeState()

	const momentDispatch = useMomentsDispatch()
	const [isContextOpen, setIsContextOpen] = useState<boolean>(false)
	const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { photoUrls, isLoading: isPhotosLoading } = useFetchPhotos({
		photos,
		userId: me?.currentTimeline?.createdBy?.id
			? me?.currentTimeline?.createdBy?.id
			: me.id
	})

	const [isDescriptionLimited, setIsDescriptionLimited] = useState<boolean>(true)
	const [isTitleLimited, setIsTitleLimited] = useState<boolean>(true)

	const momentMenu: Array<ContextMenuItemType> = [
		// {
		// 	label: 'Edit',
		// 	onClick: () => onEdit()
		// },
		{
			label: 'Delete',
			onClick: () => {
				setIsDeleteConfirmationOpen(true)
			}
		}
	]

	const closeContextMenu = () => setIsContextOpen(false)

	const deleteMoment = async () => {
		setIsLoading(true)
		closeContextMenu()
		try {
			const momentRef = doc(db, 'moments', id)
			await deleteDoc(momentRef)

			momentDispatch({ type: MomentsActions.remove, payload: id })
			setIsLoading(false)

			setIsDeleteConfirmationOpen(false)
			setIsContextOpen(false)
		} catch (error) {
			console.error(error)
			setIsLoading(false)

			setIsDeleteConfirmationOpen(false)
			setIsContextOpen(false)
		}
	}

	return (
		<Wrapper>
			<DeleteConfirmation
				isLoading={isLoading}
				isOpen={isDeleteConfirmationOpen}
				itemToBeDelete='Moment'
				onDelete={deleteMoment}
				setIsOpen={setIsDeleteConfirmationOpen}
			/>
			<Header>
				<DateText>
					<Month>{Info.months('short')[dt.month - 1]}</Month>
					<Day>{dt.toFormat('dd')}</Day>
				</DateText>
				<Context
					isVisible={isContextOpen}
					menu={momentMenu}
					onClose={closeContextMenu}
					toggle={
						<Menu onClick={() => setIsContextOpen((isOpen: boolean) => !isOpen)}>
							<EllipsesIcon />
						</Menu>
					}
					position={{
						top: 'calc(100% + 12px)',
						right: '0'
					}}
				/>
			</Header>
			<Images
				onClick={() => {
					onClick(photoUrls)
				}}>
				{Boolean(photoUrls?.length) ? (
					<>
						{map(photoUrls, (photo, index) => {
							if (index > photoUrls.length - 4) {
								return (
									<Image
										alt=''
										index={photoUrls.length - index - 1}
										key={index}
										src={photo}
									/>
								)
							}
						})}
						<Count>{photoUrls.length}</Count>
					</>
				) : (
					<NoPhoto>{isPhotosLoading ? <Loader type='fill' /> : 'No Photo'}</NoPhoto>
				)}
			</Images>

			<Title>
				{isTitleLimited ? limitString(title, TITLE_LIMIT) : title}
				<SeeMoreOrLess onClick={() => setIsTitleLimited((isLimited) => !isLimited)}>
					{title.length > TITLE_LIMIT && (isTitleLimited ? 'see more' : 'see less')}
				</SeeMoreOrLess>
			</Title>
			<Description>
				{isDescriptionLimited ? limitString(description, DESCRIPTION_LIMIT) : description}
				<SeeMoreOrLess onClick={() => setIsDescriptionLimited((isLimited) => !isLimited)}>
					{description.length > DESCRIPTION_LIMIT &&
						(isDescriptionLimited ? 'see more' : 'see less')}
				</SeeMoreOrLess>
			</Description>
		</Wrapper>
	)
}

const Wrapper = styled.div`
	align-items: flex-start;
	background-color: ${(props) => props.theme.colors.neutral001};
	display: flex;
	flex-direction: column;
	padding: 24px 12px;
	width: 100%;
	@media screen and (min-width: 768px) {
		border-radius: 12px;
		padding: 30px;
	}
`

const Header = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	margin-bottom: 12px;
	@media screen and (min-width: 768px) {
		margin-bottom: 24px;
	}
`

const DateText = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-right: auto;
`

const Month = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	font-size: 16px;
	font-weight: 600;
	left: 24px;
	@media screen and (min-width: 768px) {
		line-height: 25px;
		font-size: 20px;
	}
`

const Day = styled.span`
	font-size: 28px;
	font-weight: 600;
	line-height: 32px;
	@media screen and (min-width: 768px) {
		font-size: 36px;
		line-height: 45px;
	}
`

const Menu = styled.button`
	align-items: center;
	background-color: transparent;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	height: 50px;
	justify-content: center;
	width: 50px;

	&:hover {
		background-color: ${(props) => props.theme.colors.neutral005};
		opacity: 0.75;
	}
`

const Images = styled.div`
	position: relative;
	width: 100%;
	margin-top: 32px;
	height: 300px;
	@media screen and (min-width: 768px) {
		height: 480px;
	}
`

const Image = styled.img<{ index: number }>`
	background-color: ${(props) => props.theme.colors.neutral001};
	border: 7px solid ${(props) => props.theme.colors.neutral001};
	border-radius: 12px;
	box-shadow: 0 7px 12px ${(props) => transparentize(0.75, props.theme.colors.neutral000)};
	cursor: pointer;
	height: 100%;
	height: 300px;
	object-fit: cover;
	position: absolute;
	transform: scale(${(props) => props.index * -0.05 + 1})
		translateY(${(props) => (props.index - 1) * 25}px);
	width: 100%;
	@media screen and (min-width: 768px) {
		height: 480px;
	}
`

const Count = styled.span`
	align-items: center;
	background-color: ${(props) => props.theme.colors.neutral001};
	border-radius: 30px;
	bottom: 48px;
	display: flex;
	font-size: 16px;
	font-weight: 600;
	height: 30px;
	justify-content: center;
	position: absolute;
	right: 24px;
	width: 30px;
	z-index: 100;
`

const NoPhoto = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.colors.neutral005};
	border-radius: 12px;
	height: 100%;
	height: 300px;
	object-fit: cover;
	position: absolute;
	width: 100%;
	font-size: 24px;
	font-weight: 400;
	color: ${(props) => props.theme.colors.neutral003};
	@media screen and (min-width: 768px) {
		height: 480px;
	}
`

const Title = styled.span`
	font-weight: 600;
	font-size: 16px;
	letter-spacing: 0.015em;
	line-height: 24px;
	margin-top: 12px;
	@media screen and (min-width: 768px) {
		margin-top: 32px;
	}
`

const Description = styled.span`
	color: ${(props) => props.theme.colors.neutral000};
	font-weight: 400;
	font-size: 16px;
	letter-spacing: 0.015em;
	line-height: 24px;
	margin-top: 24px;
`

const SeeMoreOrLess = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	cursor: pointer;
	font-weight: 400;
	&:hover {
		color: ${(props) => props.theme.colors.primary};
	}
`

const Time = styled.span`
	color: ${(props) => props.theme.colors.neutral003};
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	margin-top: 10px;
`

export default Moment
