import { collection, getDocs, query, where } from 'firebase/firestore'
import { db } from 'firebaseConfig'
import { map } from 'lodash'
import { useMeState } from 'providers/me'
import { useEffect, useState } from 'react'
import { useTimelinesDispatch } from '.'
import { TimelineProps, TimelinesActions } from './types'

export const useFetchTimelines = () => {
	const [data, setData] = useState<Array<TimelineProps>>()
	const timelineDispatch = useTimelinesDispatch()
	const me = useMeState()

	useEffect(() => {
		const fn = async () => {
			try {
				timelineDispatch({ type: TimelinesActions.updateLoading, payload: true })

				const timelinesRef = collection(db, 'timelines')

				const timelinesQuery = query(timelinesRef, where('createdBy.id', '==', me.id))
				const timelinesSnapshot = await getDocs(timelinesQuery)

				const payload: Array<TimelineProps> = map(timelinesSnapshot.docs, (doc) => {
					return {
						id: doc.id,
						image: doc.data().image,
						name: doc.data().name,
						description: doc.data().description,
						createdAt: doc.data().createdAt,
						updatedAt: doc.data().updatedAt,
						createdBy: me,
						invitees: doc.data().invitees
					}
				})

				timelineDispatch({ type: TimelinesActions.fetch, payload })
			} catch (err) {
				//catch error
				timelineDispatch({ type: TimelinesActions.updateLoading, payload: false })
			}
		}

		fn()

		return () => {}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [me.id])

	return [data, setData]
}
