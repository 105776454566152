import { createContext, Dispatch, FC, ReactNode, useContext, useReducer } from 'react'
import reducer from './reducer'
import { TimelinesActionProps, TimelinesStateProps } from './types'

export const initialState: TimelinesStateProps = {
	data: [],
	isLoading: false,
	count: 0
}

export const TimelinesState = createContext<TimelinesStateProps>(initialState)
export const TimelinesDispatch = createContext<Dispatch<TimelinesActionProps>>(() => null)
export const useTimelinesState = () => useContext(TimelinesState)
export const useTimelinesDispatch = () => useContext(TimelinesDispatch)

export const TimelinesProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState, () => initialState)

	return (
		<TimelinesState.Provider value={state}>
			<TimelinesDispatch.Provider value={dispatch}>{children}</TimelinesDispatch.Provider>
		</TimelinesState.Provider>
	)
}
