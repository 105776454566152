import { useEffect, useState } from 'react'

const useFetchFonts = () => {
	const googleFontApiKey = process.env.REACT_APP_GOOGLE_FONTS_API_KEY
	const [fonts, setFonts] = useState([])

	useEffect(() => {
		const fn = async () => {
			const response = await fetch(
				`https://www.googleapis.com/webfonts/v1/webfonts?sort=POPULARITY&key=${googleFontApiKey}`
			)
			const googleFont = await response.json()

			setFonts(googleFont.items)
		}

		fn()
	}, [googleFontApiKey])

	return fonts
}

export default useFetchFonts
