import { FC, FormEvent, useLayoutEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components/macro'

export type TextareaProps = {
	error?: string
	isBlocked?: boolean
	label?: string
	labelBackground?: string
	maxlength?: number
	minHeight?: number
	placeholder?: string
	value: string | number
	onBlur?: (e: FormEvent<HTMLTextAreaElement>) => void
	onChange: (e: FormEvent<HTMLTextAreaElement>) => void
	onFocus?: (e: FormEvent<HTMLTextAreaElement>) => void
}

const Textarea: FC<TextareaProps> = ({
	error = '',
	isBlocked = false,
	label,
	labelBackground = '',
	maxlength,
	minHeight = 100,
	placeholder,
	value,
	onBlur,
	onChange,
	onFocus,
	...rest
}: TextareaProps) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null)

	const [textareaHeight, setTextareaHeight] = useState<string>('100px')
	const [isFocus, setIsFocus] = useState<boolean>(false)

	useLayoutEffect(() => {
		if (textareaRef?.current) {
			const textareaElement: HTMLElement = textareaRef.current

			const scrollHeight = textareaElement.scrollHeight
			setTextareaHeight(scrollHeight > minHeight ? `${scrollHeight}px` : `${minHeight}px`)
		}
	}, [value])

	return (
		<Wrapper
			{...rest}
			isBlocked={isBlocked}
			hasError={Boolean(error)}
			hasLabel={Boolean(label)}
			isFocus={isFocus}>
			<InputWrapper
				ref={textareaRef}
				onBlur={(e) => {
					setIsFocus(false)
					onBlur && onBlur(e)
				}}
				onChange={(e) => {
					onChange(e)
					setTextareaHeight('auto')
				}}
				onFocus={(e) => {
					setIsFocus(true)
					onFocus && onFocus(e)
				}}
				maxLength={maxlength}
				placeholder={placeholder}
				value={value}
				style={{
					height: `${textareaHeight}`
				}}></InputWrapper>
			{label && (
				<Label
					isActive={Boolean(`${value}`.trim()) || isFocus || Boolean(placeholder)}
					background={labelBackground}>
					{label}
				</Label>
			)}
			<Error>{error}</Error>
		</Wrapper>
	)
}

const Wrapper = styled.div<{
	isBlocked: boolean
	hasError: boolean
	hasLabel: boolean
	isFocus: boolean
}>`
	border-radius: 12px;
	border: 2px solid
		${(props) =>
			props.isFocus ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	display: flex;
	flex-direction: column-reverse;
	padding: 11px 15px;
	position: relative;
	text-align: left;
	transition: all 250ms ease-in-out;
	width: ${(props) => (props.isBlocked ? '100%' : 'auto')};
`

const Label = styled.label<{ isActive: boolean; background: string }>`
	background: ${(props) =>
		props.background ? props.background : props.theme.colors.neutral005};
	border-radius: 4px;
	color: ${(props) =>
		props.isActive ? props.theme.colors.neutral000 : props.theme.colors.neutral003};
	font-size: 18px;
	left: 10px;
	padding: 0 5px;
	position: absolute;
	top: 14px;
	transform-origin: left;
	transition: all 250ms ease-in-out;
	z-index: 0;
	${(props) =>
		props.isActive &&
		css`
			top: -10px;
			transform: scale(0.8);
		`}
`

const InputWrapper = styled.textarea`
	background: none;
	border-radius: 12px;
	color: ${(props) => props.theme.colors.neutral000};
	font-family: ${(props) => props.theme.font.family.default};
	font-size: 18px;
	height: auto;
	outline: none;
	resize: none;
	z-index: 1;
`
const Error = styled.div`
	bottom: -15px;
	color: ${(props) => props.theme.colors.danger};
	font-size: 12px;
	font-weight: 600;
	left: 0;
	position: absolute;
`
export default Textarea
