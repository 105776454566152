import PrivateElement from 'components/block/privateElement'
import PublicElement from 'components/block/publicElement'
import GlobalStyle from 'globalStyle'
import Appearance from 'pages/appearance'
import Homepage from 'pages/home'
import Login from 'pages/login'
import Moments from 'pages/momentManager'
import PageNotFound from 'pages/pageNotFound'
import Settings from 'pages/settings'
import Signup from 'pages/signup'
import Timeline from 'pages/timeline'
import Timelines from 'pages/timelineManager'
import TimelineForm from 'pages/timelineManager/form'
import { MomentsProvider } from 'providers/moments'
import { UsersProvider } from 'providers/users'
import { Route, Routes } from 'react-router'
import { ThemeProvider } from 'styled-components/macro'
import theme from 'theme'

function App() {
	return (
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Routes>
				<Route path='/' element={<Homepage />} />
				<Route
					path='/appearance'
					element={
						<PrivateElement>
							<Appearance />
						</PrivateElement>
					}
				/>
				<Route
					path='/login'
					element={
						<PublicElement>
							<Login />
						</PublicElement>
					}
				/>
				<Route
					path='/settings'
					element={
						<PrivateElement>
							<UsersProvider>
								<Settings />
							</UsersProvider>
						</PrivateElement>
					}
				/>
				<Route
					path='/signup'
					element={
						<PublicElement>
							<Signup />
						</PublicElement>
					}
				/>
				<Route
					path='/timeline'
					element={
						<PrivateElement>
							<MomentsProvider>
								<Moments />
							</MomentsProvider>
						</PrivateElement>
					}
				/>

				<Route
					path='/timelines'
					element={
						<PrivateElement>
							<Timelines />
						</PrivateElement>
					}
				/>
				<Route
					path='/timelines/add'
					element={
						<PrivateElement>
							<TimelineForm />
						</PrivateElement>
					}
				/>
				<Route
					path='/timelines/:id'
					element={
						<PrivateElement>
							<TimelineForm />
						</PrivateElement>
					}
				/>

				<Route path='/timeline/:userId/:timelineId' element={<Timeline />} />
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</ThemeProvider>
	)
}

export default App
